// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#resetPin {
  background-color: #000;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/resetPin/resetPin.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,aAAA;AACJ","sourcesContent":["#resetPin {\n    background-color: #000;\n    height: 100vh;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
