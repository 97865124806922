import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import './userProfileInfo.scss'
import Username from './userProfileInfo/username';
import UserDate from './userProfileInfo/userdate';
import Usergender from './userProfileInfo/usergender';
import UserNation from './userProfileInfo/usernation';
import UserEmail from './userProfileInfo/useremail';
import UserPhone from './userProfileInfo/userphone';
import UserPass from './userProfileInfo/userpass';
import UserLang from './userProfileInfo/userlang';



const UserProfileInfo = () => {
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userId");
        navigate("/login")
    }

    const [showNameModal, setShowNameModal] = useState(false);
    const [showDateModal, setShowDateModal] = useState(false);
    const [showGenderModal, setShowGenderModal] = useState(false);
    const [showNationModal, setShowNationModal] = useState(false);
    const [showPhoneModal, setShowPhoneModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showPassModal, setShowPassModal] = useState(false);
    const [showLangModal, setShowLangModal] = useState(false);


    const [showIssueModal, setShowIssueModal] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showSilvermanModal, setShowSilvermanModal] = useState(false);


    const handleNameModal = () => {
        setShowNameModal(true)
    }
    const handleDateModal = () => {
        setShowDateModal(true)
    }
    const handleGenderModal = () => {
        setShowGenderModal(true)
    }
    const handleNationModal = () => {
        setShowNationModal(true)
    }
    const handleEmailModal = () => {
        setShowEmailModal(true)
    }
    const handlePhoneModal = () => {
        setShowPhoneModal(true)
    }
    const handlePassModal = () => {
        setShowPassModal(true)
    }
    const handleLangModal = () => {
        setShowLangModal(true)
    }

    const handleCloseModal = () => {
        setShowNameModal(false)
        setShowDateModal(false)
        setShowGenderModal(false)
        setShowNationModal(false)
        setShowPhoneModal(false)
        setShowEmailModal(false)
        setShowPassModal(false)
        setShowLangModal(false)

        setShowIssueModal(false)
        setShowPrivacyModal(false)
        setShowSilvermanModal(false)
    }

    const handleUserTerm = ()=>{
        navigate("/userTerm")
    }
    const handlePDPA = ()=>{
        navigate("/pdpa")
    }

    return (
        <div id="userProfileInfo">
            {/* <h5 className='mt-3 mb-2'>ข้อมูลโครงการ</h5>
            <div className='infoContainer'>
                <div className='infoDetail d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/building.png'} />
                        </div>
                        <span className=''>บ้านของฉัน</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">ยังไม่ระบุ</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
            </div>

            <h5 className='mt-3 mb-2'>ข้อมูลบุคคล</h5>
            <div className='infoContainer'>
                <div className='infoDetail d-flex flex-row justify-content-between' onClick={handleNameModal}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/person.svg'} />
                        </div>
                        <span className=''>ชื่อ</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">ยังไม่ระบุ</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
                <div className='infoDetail d-flex flex-row justify-content-between' onClick={handleDateModal}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/cake.svg'} />
                        </div>
                        <span className=''>วันเกิด</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">ยังไม่ระบุ</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
                <div className='infoDetail d-flex flex-row justify-content-between' onClick={handleGenderModal}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/gender.png'} />
                        </div>
                        <span className=''>เพศ</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">ยังไม่ระบุ</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
                <div className='infoDetail d-flex flex-row justify-content-between' onClick={handleNationModal}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/nationality.png'} />
                        </div>
                        <span className=''>สัญชาติ</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">ยังไม่ระบุ</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
            </div>

            <h5 className='mt-3 mb-2'>ข้อมูลการเชื่อมต่อ</h5>
            <div className='infoContainer'>

                <div className='infoDetail d-flex flex-row justify-content-between' onClick={handlePhoneModal}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/phone.svg'} />
                        </div>
                        <span className=''>เบอร์โทรศัพท์</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">ยังไม่ระบุ</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
                <div className='infoDetail d-flex flex-row justify-content-between' onClick={handleEmailModal}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/mail.svg'} />
                        </div>
                        <span className=''>อีเมล</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">ยังไม่ระบุ</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
                <div className='infoDetail d-flex flex-row justify-content-between' onClick={handlePassModal}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/unlock.svg'} />
                        </div>
                        <span className=''>รหัส</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">เปลี่ยนรหัสผ่าน</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
            </div>

            <h5 className='mt-3 mb-2'>เกี่ยวกับแอปพลิเคชั่น</h5>
            <div className='infoContainer'>
                <div className='infoDetail d-flex flex-row justify-content-between' onClick={handleLangModal}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/language.svg'} />
                        </div>
                        <span className=''>ภาษา</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <span className="me-2">ภาษาไทย</span>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
                <div className='infoDetail d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/doc2.svg'} />
                        </div>
                        <span className=''>เงื่อนไขการให้บริการ</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
                <div className='infoDetail d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/privacy.svg'} />
                        </div>
                        <span className=''>นโยบายความเป็นส่วนตัว</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
                <div className='infoDetail d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chat.svg'} />
                        </div>
                        <span className=''>ติอต่อ Silverman</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} />                        
                    </div>
                </div>
            </div> */}
            <h5 className='mt-3 mb-2'>แนวทางปฏิบัติ</h5>
            <div className='infoContainer mb-5 '>
            <div className='infoDetail d-flex flex-row justify-content-between' onClick={handleUserTerm}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/doc2.svg'} alt="editProfileIconDoc"/>
                        </div>
                        <span className=''>แนวทางปฏิบัติ</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} alt="editProfileIconDoc"/>                        
                    </div>
            </div>     
            <div className='infoDetail d-flex flex-row justify-content-between' onClick={handlePDPA}>
                    <div className='d-flex flex-row'>
                        <div className='imgContainer d-flex align-items-center'>
                            <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/doc2.svg'} alt="editProfileIconDoc"/>
                        </div>
                        <span className=''>คุ้มครองข้อมูลส่วนบุคคล</span>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/chevronRight.png'} alt="editProfileIconChevronRight"/>                        
                    </div>
            </div>         
            </div>

            <h5 className='mt-3 mb-2'>ออกจากระบบ</h5>
            <div className='infoContainer mb-5 logout'>
                <div className='infoDetail d-flex flex-row' onClick={logout}>
                     <div className='imgContainer d-flex align-items-center'>
                        <img src={process.env.PUBLIC_URL+'/images/editProfileIcon/logout.svg'} alt="editProfileIconLogout"/>
                    </div>
                    <span className=''>ออกจากระบบ</span>                    
                </div>
            </div>            

            <Username 
                showModal={showNameModal}
                handleCloseModal={handleCloseModal}
            />     
            <UserDate 
                showModal={showDateModal}
                handleCloseModal={handleCloseModal}
            />     
            <Usergender 
                showModal={showGenderModal}
                handleCloseModal={handleCloseModal}
            />  
            <UserNation 
                showModal={showNationModal}
                handleCloseModal={handleCloseModal}
            />  
            <UserEmail 
                showModal={showEmailModal}
                handleCloseModal={handleCloseModal}
            />
            <UserPhone 
                showModal={showPhoneModal}
                handleCloseModal={handleCloseModal}
            />
            <UserPass 
                showModal={showPassModal}
                handleCloseModal={handleCloseModal}
            />
            <UserLang 
                showModal={showLangModal}
                handleCloseModal={handleCloseModal}
            />
        </div>
        
    )
}

export default UserProfileInfo