// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ci_UseAll .mt12 {
  margin-top: 12px;
}
.Ci_UseAll .mb12 {
  margin-bottom: 12px;
}
.Ci_UseAll .ml12 {
  margin-left: 12px;
}
.Ci_UseAll .mr12 {
  margin-right: 12px;
}
.Ci_UseAll .text-center {
  text-align: center !important;
}
.Ci_UseAll .ml-12 {
  margin-left: 12px !important;
}
.Ci_UseAll .w36 {
  width: 36px !important;
}
.Ci_UseAll .h48 {
  height: 48px !important;
}
.Ci_UseAll .text-black {
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/index.scss"],"names":[],"mappings":"AAYI;EACI,gBAAA;AAXR;AAaI;EACI,mBAAA;AAXR;AAaI;EACI,iBAAA;AAXR;AAaI;EACI,kBAAA;AAXR;AAcI;EACI,6BAAA;AAZR;AAeI;EACI,4BAAA;AAbR;AAgBI;EACI,sBAAA;AAdR;AAiBI;EACI,uBAAA;AAfR;AAkBI;EACI,uBAAA;AAhBR","sourcesContent":["// #feedCard {\n//     .feedCategory {\n//         background-color: #E6F2FE;\n//         color: #1567B4;\n//         border-radius: 20px;\n//         padding: 4px 8px;\n//         font-weight: 600;\n//         font-size: 10px;\n//     }\n// }\n\n.Ci_UseAll {\n    .mt12 {\n        margin-top: 12px;\n    }\n    .mb12 {\n        margin-bottom: 12px;\n    }\n    .ml12 {\n        margin-left: 12px;\n    }\n    .mr12 {\n        margin-right: 12px;\n    }\n\n    .text-center {\n        text-align: center !important;\n    }\n\n    .ml-12 {\n        margin-left: 12px !important;\n    }\n\n    .w36 {\n        width: 36px !important;\n    }\n\n    .h48 {\n        height: 48px !important;\n    }\n\n    .text-black {\n        color: black !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
