import React from 'react'
import { NavLink } from 'react-router-dom';
import './createPostBtn.scss';

const CreatePostBtn = ()=>{

    return (
        <> 
            <NavLink to="/createpost">
                    <img className="mt-4 addPost" src={process.env.PUBLIC_URL + `/images/icon/addPostIcon.png`} alt="profile" 
                    width="48px" height="48px"
                />
            </NavLink>
        </>
    )
}

export default CreatePostBtn