const allCategories = [
    { engName: "all", thName: "ทั้งหมด" },
    { engName: "accounting", thName: "บัญชี-การเงินนิติบุคคล" },
    { engName: "entity", thName: "บริหารนิติบุคคล" },
    { engName: "maintenance", thName: "ซ่อมบำรุง" },
    { engName: "revenue", thName: "รายได้นิติบุคคล" },
    { engName: "meeting", thName: "จัดประชุมใหญ่" },
    { engName: "contact", thName: "จัดการผู้มาติดต่อ" },
    { engName: "legal", thName: "ปรึกษาด้านกฏหมาย" },
    { engName: "seminar", thName: "งานสัมนา" },    
    { engName: "homemaker", thName: "แม่บ้าน-รปภ." },    
    { engName: "tech", thName: "เทคโนโลยี" },    
    { engName: "marketplace", thName: "ซื้อ-ขาย-เช่า" },    
    { engName: "market-recommend", thName: "แนะนำร้านค้า-บริการ" },    
    { engName: "etc", thName: "Lost & Found" },    
    { engName: "lost-found", thName: "อื่นๆ" },    
  ];

export default allCategories;