import React , {useState} from 'react';
import { Container} from 'react-bootstrap'
import './pinPage.scss'
import LoginHeader from './loginHeader';
import PinInput from "react-otp-input";
import loginApi from '../../../service/serviceLogin';
import socialApi from '../../../service/services';
import { useUserName } from '../userNameContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../helper/loading';
import { Link } from 'react-router-dom';

const PinConfirmPage = (props) => {
    const userNameLogin = localStorage.getItem('userName')
    const  {setUserId} = useUserName()
    const  [loading, setLoading] = useState(false)
    
    const userService = new socialApi.User()
    const loginPIN = new loginApi.PIN()
    const processLogin = new loginApi.ProcessLogin()

    const [pin, setPin] = useState('');
    const [wrongPin , setWrongPin] = useState(false)
    const navigate = useNavigate()
    
    const handlePinChange = (inputPin) => {
        setPin(inputPin);
        if (inputPin.length === 6) {                  
            confirmPin(inputPin)
        } else {
            setWrongPin(false)
        }
    };


    const confirmPin = async (inputPin) => {      
        setLoading(true)  
        const data = {
            "password": inputPin
        }
        try {
            let checkLogin = await processLogin.checkProcessLogin(userNameLogin)
    
            const resAuthPin = await loginPIN.authPIN(data)
                if (resAuthPin.data.status) {
                    localStorage.setItem("authToken", resAuthPin.data.token);

                    const resLogin = await userService.getUserLogin(userNameLogin)
                        if (resLogin.data.user !== "noUser") {                            
                            localStorage.setItem("userId", resLogin.data.user._id);
                            setUserId(resLogin.data.user._id)
                                if(!checkLogin.data.pdpa){
                                    navigate("/pdpa")
                                } else {
                                    navigate("/feed/all")
                                }                            
                        } else if(resLogin.data.user === "noUser") {
                            if(!checkLogin.data.pdpa){
                                navigate("/pdpa")
                            } else {
                                navigate("/selectProfile")
                            }
                        }                       

                } else {
                    setWrongPin(true);
                    setLoading(false)
                    setPin('')
                    return;
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div id="pinPage">
            <LoginHeader 
                handleStateLoginChange={()=>props.handleStateLoginChange("otp")}
                headerText="ยืนยันรหัส"
            />
            <Container className='d-flex flex-column align-items-center pt-5'>
                    <div className='pinTextDescription d-flex flex-column align-items-center'>
                            <div className='mb-5 d-flex flex-column align-items-center'>
                                <p>ยืนยันรหัส PIN</p>
                                <p>Confirm your number PIN</p>
                                {wrongPin && <p className='textRed m-0'>รหัส PIN ไม่ถูกต้อง</p>}
                            </div>    
                        {loading?
                        <Loading />
                            :       
                        <>                                   
                        <PinInput
                            value={pin}
                            onChange={handlePinChange}
                            numInputs={6}
                            renderSeparator={<span style={{ width: "8px" }}></span>}
                            inputType="number"
                            isInputNum={true}
                            shouldAutoFocus={true}      
                            renderInput={(props, index) => {
                                return (
                                <input
                                    {...props}
                                    value=""
                                    style={{
                                        border: `2px solid ${wrongPin ? "red":
                                        pin?.length === index ? "white" : "transparent"}`,
                                        borderRadius: "50%",
                                        width: "30px",
                                        height: "30px",
                                        fontSize: "12px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "transparent",
                                        backgroundColor: props.value ? "#ffffff" : "#4D4D4D",
                                    }}
                                />
                            )}}                     
                        />
                        <Link to="/reset_pin" state={{ haveOtp: true }}>
                            <p className='mt-5'>ลืมรหัส PIN</p>
                        </Link>
                        </>   
                        }        
                    </div>
            </Container>
        </div>
    )
}

export default PinConfirmPage