import React, {useState} from 'react';
import { Container} from 'react-bootstrap'
import './term.scss';

const TermPartner = (props) => {
    const [accept, setAccept] = useState(true);

    const handlePdpaProcess =  () => {
        props.handleAcceptPartner(accept)
       
    }
    return (
        <div id="termContainer">
                 <div className="iq-top-navbar bg-dark d-flex flex-row justify-content-center align-items-center p-4">                  
                        <h2 className="text-white ms-3">จัดการข้อมูลส่วนตัว</h2>
                  </div>
                  <Container>
                  <div className='termDescriptionContainer'>
                        <h5 className='text-center m-4'>ความยินยอมสำหรับพันธมิตรของ Silverman</h5>
                        <p>พันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman ได้แก่</p>                                      
                        <ul>
                            <li>การให้บริการจัดประชุมใหญ่ผ่านสื่ออิเล็กทรอนิกส์ (E-AGM) ซึ่งขณะนี้คือ บริษัท ควิดแลบ จำกัด</li>
                            <li>การให้บริการระบบปล่อยเช่าที่จอดรถที่สามารถเชื่อมโยงหรือส่งข้อมูลถึงกันได้ด้วยอินเทอร์เน็ตโดยให้เจ้าของที่จอดรถสามารถปล่อยเช่าที่จอดรถของตนเองผ่านแพลตฟอร์ม (Parking Sharing)  ซึ่งขณะนี้คือ บริษัท อินฟิไลท์ จำกัด</li>
                            <li>การให้บริการ Telehealth & Telemedicine ซึ่งขณะนี้คือ บริษัท รีลีฟนาว จำกัด</li>
                        </ul>                        
                        <p>คุณมีสิทธิขอถอนความยินยอมเมื่อใดก็ได้ ผ่าน Silverman Applicationสำนักงานใหญ่ของบริษัท พอร์ต 80 จำกัด, Silverman Call Center โทร. 081-442-6888 และ/หรือช่องทางที่บริษัทกำหนดในภายหน้า โดยสามารถดูรายละเอียดช่องทางการยกเลิกการให้ความยินยอมได้ที่ประกาศนโยบายความเป็นส่วนตัวของ Silverman  ทั้งนี้ Silverman จะพิจารณาดำเนินการภายใน 7 วัน นับแต่วันที่ได้รับการแจ้งถอนความยินยอม และหลังจากนั้น Silverman จะไม่เปิดเผยข้อมูลส่วนบุคคลและข้อมูลใดๆ ของคุณให้แก่บุคคลดังกล่าวอีกต่อไป</p>
                        <p>คุณสามารถอ่านวิธีที่ Silverman เก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล และเข้าใจสิทธิของคุณที่ประกาศนโยบายความเป็นส่วนตัวที่เว็บไซต์ของ Silverman www.silverman.app ทั้งนี้ โปรดอ่านประกาศนโยบายความเป็นส่วนตัวของบริษัทและพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman ที่เว็บไชต์หรือช่องทางที่บริษัทและพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman กำหนด</p>
                        <p>ยินยอมในการเปิดเผยข้อมูลสำหรับพันธมิตรของ Silverman</p>
                        <div className='d-flex justify-content-around'>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" 
                                id="flexRadioDefault1" 
                                checked={accept === true}
                                onChange={() => setAccept(true)}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    ยินยอม
                                </label>
                                </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" 
                                checked={accept === false}
                                onChange={() => setAccept(false)}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    ปฏิเสธ
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-5'>                 
                            <button className='nextBtn' onClick={()=>handlePdpaProcess()}>ถัดไป</button>
                        </div>
                  </div>
                  </Container>
        </div>
    )
}

export default TermPartner

 


