import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Container} from 'react-bootstrap'

const UserTerm = () => {
    const navigate = useNavigate()
    const backPage = () => {
        navigate(-1)
    }
    return (
        <>
        <div className="iq-top-navbar bg-dark d-flex flex-row justify-content-between align-items-center p-4">   
            <img
                src={process.env.PUBLIC_URL + `/images/icon/leftArrow.svg`}
                className="leftArrow"
                alt="user"
                loading="lazy"
                onClick={backPage}
            />              
            <h2 className="text-white ms-3">แนวทางปฏิบัติ</h2>
            <div></div>
        </div>
        <Container>        
                <div className='termDescriptionContainer'> 
                <h5 className='textHeader text-center m-4'>แนวทางปฏิบัติของผู้ใช้งาน Silverman Society Club</h5> 
                <p>ยินดีต้อนรับทุกท่านเข้าสู่สังคมคุณภาพ Silverman Society Club เพื่อแลกเปลี่ยนไอเดียในการพัฒนานิติบุคคลของเรา</p>

                <p>เพื่อให้การสื่อสารเป็นไปอย่างราบรื่นและปลอดภัย ทางทีมงานจึงได้แนะนำและกำหนดแนวทางปฏิบัติสำหรับการใช้งาน Silverman Society Club พร้อมข้อควรระวังดังรายละเอียดต่อไปนี้;</p>

                <p>1. ระวังข้อมูลส่วนตัว:</p>

                <p>อย่าแชร์ข้อมูลส่วนตัว เช่น เบอร์โทรศัพท์ ที่อยู่ ข้อมูลทางการเงิน ฯลฯ บนโซเชียลมีเดีย
                ตั้งค่าความเป็นส่วนตัวของบัญชีโซเชียลมีเดีย
                ตรวจสอบและลบข้อมูลส่วนตัวที่ไม่จำเป็นออกจากโซเชียลมีเดีย</p>

                <p>2. ระวังภัยออนไลน์:</p>

                <p>ระวังการถูกหลอกลวง ขโมยข้อมูล หรือคุกคามทางไซเบอร์
                ใช้รหัสผ่านที่คาดเดายาก
                อัปเดตซอฟต์แวร์อยู่เสมอ</p>

                <p>3. ปฏิบัติตามกฎของชุมชน:</p>

                <p>อ่านและเข้าใจกฎระเบียบของชุมชนออนไลน์
                โพสต์เนื้อหาที่เหมาะสม สุภาพ และไม่สร้างความเกลียดชัง
                แจ้งปัญหาหรือข้อเสนอแนะให้ผู้ดูแลชุมชน</p>

                <p>4. ซื้อขายออนไลน์อย่างปลอดภัย:</p>

                <p>ตรวจสอบสินค้า/บริการอย่างละเอียดก่อนซื้อ
                ระวังมิจฉาชีพ
                ทำธุรกรรมผ่านช่องทางที่ปลอดภัย</p>

                <p>5. ใช้ภาษาสุภาพ:</p>

                <p>ใช้ภาษาที่สุภาพ เหมาะสม และเคารพผู้อื่น
                หลีกเลี่ยงการใช้คำหยาบคายหรือไม่เหมาะสม</p>

                <p>6. เคารพความหลากหลาย:</p>

                <p>ยอมรับความแตกต่างของผู้อื่น
                เปิดใจเรียนรู้วัฒนธรรมอื่น
                ต่อต้านการเลือกปฏิบัติ</p>

                <p>7. ใช้โซเชียลมีเดียเพื่อประโยชน์</p>:

                <p>แชร์ข้อมูลข่าวสารที่มีประโยชน์
                ระดมทุนเพื่อการกุศล
                สร้างการเปลี่ยนแปลงในสังคม</p>

                <p>8. จัดการความขัดแย้งอย่างสุภาพ:</p>

                <p>รักษาความสุภาพ
                มุ่งหาทางออกร่วมกัน
                หลีกเลี่ยงการใช้คำพูดที่รุนแรง</p>

                <p>9. ตรวจสอบข้อมูลก่อนแชร์:</p>

                <p>อย่าแชร์ข่าวปลอม
                ตรวจสอบความถูกต้องของข้อมูลก่อนแชร์
                แชร์ข้อมูลจากแหล่งที่น่าเชื่อถือ</p>

                <p>10. เคารพลิขสิทธิ์ผู้อื่น:</p>

                <p>อย่าโพสต์เนื้อหาที่ละเมิดลิขสิทธิ์
                เรียนรู้เกี่ยวกับกฎหมายลิขสิทธิ์
                สนับสนุนการสร้างสรรค์ผลงานดั้งเดิม</p>

                <p>11. ห้ามพูดคุยเกี่ยวกับเรื่องศาสนา:</p>

                <p>การพูดคุยเรื่องศาสนาอาจนำไปสู่ความขัดแย้ง
                ทางทีมงานขอแนะนำให้หลีกเลี่ยงการพูดคุยเรื่องศาสนาใน Silverman Society Club</p>

                <p> 12. ห้ามพูดคุยเรื่องการเมือง:</p>

                <p> การพูดคุยเรื่องการเมืองอาจนำไปสู่ความขัดแย้ง
                ทางทีมงานขอแนะนำให้หลีกเลี่ยงการพูดคุยเรื่องการเมืองใน Silverman Society Club</p>


                <p>ด้วยความร่วมมือของทุกคน เราสามารถใช้ Silverman Society Club ได้อย่างสร้างสรรค์ ปลอดภัย และเกิดประโยชน์ต่อทุกคนและนิติบุคคลของพวกเราทุกคน</p>

                <p>หมายเหตุ:</p>
                <p>แนวทางปฏิบัตินี้สามารถปรับเปลี่ยนได้ตามความเหมาะสม</p>
                <p>ทางทีมงาน Silverman Society Club ขอสงวนสิทธิ์ในการลบเนื้อหาที่ไม่เหมาะสม</p>

                <p>ขอบคุณสำหรับความร่วมมือ!</p>
                <p>Silverman Team</p>
                            
                </div> 
        </Container>
        </>
    )
}

export default UserTerm