import React, {useState} from 'react';
import { Container} from 'react-bootstrap'
import './term.scss';

const MarketingReveal = (props) => {
    const [accept, setAccept] = useState(true);

    const handlePdpaProcess = ()=> {
        props.handleAcceptMarketingReveal(accept)
        props.handleStepTerm(5)
    }

    return (
        <div id="termContainer">
                  <div className="iq-top-navbar bg-dark d-flex flex-row justify-content-center align-items-center p-4">                 
                        <h2 className="text-white ms-3">จัดการข้อมูลส่วนตัว</h2>
                  </div>
                  <Container>
                  <div className='termDescriptionContainer'>
                        <h5 className='text-center m-4'>ความยินยอมในการเปิดเผยข้อมูลเพื่อวัตถุประสงค์ทางการตลาด</h5>
                        <p>เราอาจเปิดเผยข้อมูลส่วนบุคคลและข้อมูลใดๆ ของคุณ ให้แก่บริษัท ในกลุ่มและพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman เพื่อวัตถุประสงค์ดังนี้</p>                                      
                        <ul>
                            <li>วิจัย ทำข้อมูลสถิติ พัฒนา วิเคราะห์ ผลิตภัณฑ์ บริการ และสิทธิประโยชน์ที่ตอบสนองความต้องการของคุณ</li>
                            <li>ติดต่อคุณเพื่อเสนอหรือจัดให้มีผลิตภัณฑ์ บริการ และสิทธิประโยชน์ที่เหมาะสมแก่คุณ</li>
                        </ul>                        
                        <p>กรุณากด "ยินยอม" หากคุณตกลงให้ บริษัท พอร์ต 80 จำกัด เปิดเผยข้อมูลส่วนบุคคลและข้อมูลใดๆ ของคุณ ให้แก่บริษัทในกลุ่มของ Silverman สามารถดูรายละเอียดได้ที่ https://www.silverman.app และพันธมิตรทางธุรกิจที่เชื่อถือได้ของ Silverman เพื่อวัตถุประสงค์ทางการตลาดของบุคคลดังกล่าว</p>
                        <p>ยินยอมในการเปิดเผยข้อมูลเพื่อวัตถุประสงค์การตลาดได้</p>
                        <div className='d-flex justify-content-around'>
                            <div className="form-check">
                                <input className="form-check-input" 
                                type="radio" 
                                name="flexRadioDefault" 
                                id="flexRadioDefault1" 
                                checked={accept === true}
                                onChange={() => setAccept(true)}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    ยินยอม
                                </label>
                                </div>
                            <div className="form-check">
                                <input 
                                className="form-check-input" 
                                type="radio" 
                                name="flexRadioDefault" 
                                id="flexRadioDefault2" 
                                checked={accept === false}
                                onChange={() => setAccept(false)}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    ปฏิเสธ
                                </label>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-5'>                 
                            <button className='nextBtn' onClick={()=>handlePdpaProcess()}>ถัดไป</button>
                        </div>
                  </div>
                  </Container>
        </div>
    )
}

export default MarketingReveal

 


