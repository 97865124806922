import React from 'react'
import { Modal } from 'react-bootstrap';
import './userModalStyle.scss'

const Username = (props) => {
    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal} centered className="customModalWithInput">            
            <img className="close" onClick={props.handleCloseModal} src={process.env.PUBLIC_URL +  `/images/icon/closeBtn.svg`}/>
            <div className='inputContainer d-flex flex-column align-items-center'>
                <p className='text-center mb-5'>กรอก ชื่อ-นามสกุล</p>
                <p className='mt-4 mb-2'>ชื่อ</p>
                <input 
                    placeholder='ชื่อ'
                />
                <p className='mt-3 mb-2'>นามสกุล</p>
                <input 
                     placeholder='นามสกุล'
                />
                <button className='btnConfirm mt-5'>ยืนยัน</button>
            </div>
        </Modal>
    )
}

export default Username