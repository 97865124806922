import React, { createContext, useContext, useState, useEffect } from 'react';

const UserNameContext = createContext();

export const UserNameProvider = ({ children }) => {
  const [userNameLogin, setUserNameLogin] = useState('');
  const [userId, setUserId] = useState(localStorage.getItem('userId'));

  useEffect(() => {
    if (!userId) {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId) {
        setUserId(storedUserId);
      }
    }    
  }, [userId]);

  const setUserName = (userName) => {    
    setUserNameLogin(userName);
  };

  return (
    <UserNameContext.Provider value={{ userNameLogin, setUserName, userId, setUserId }}>
      {children}
    </UserNameContext.Provider>
  );
};

export const useUserName = () => {
  return useContext(UserNameContext);
};