// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#otpPage {
  height: 100vh;
}
#otpPage .clockLogo {
  width: 20px;
  height: 20px;
}
#otpPage .countDownOtp, #otpPage .inputContainer {
  color: #ffffff;
}
#otpPage .requestOtpAgain {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/login/otpPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;AAER;AAAI;EACI,cAAA;AAER;AAAI;EACI,eAAA;AAER","sourcesContent":["#otpPage {\n    height: 100vh;\n    .clockLogo {\n        width: 20px;\n        height: 20px;\n    }\n    .countDownOtp, .inputContainer {\n        color: #ffffff;\n    }\n    .requestOtpAgain {\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
