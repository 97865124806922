import React, {useState, useEffect}  from 'react'
import {NavLink, useLocation, useParams, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Card from '../../helper/Card'

import Navigation from './user-profile-navigation'
import {  Col, Container} from 'react-bootstrap'
import UserEmpty from './user-profile-empty'
import BotBar from '../../helper/botBar'
import HeaderOther from '../../helper/HeaderStyle/headerOther'
import PostList from '../../postList/postList'
import EditModal from '../../helper/editModal'
import './user-profile.scss'
import Loading from '../../helper/loading'
import socialApi from '../../../../service/services'
import { motion } from 'framer-motion'
import CreatePostBtn from '../../postList/createPostBtn'
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserName } from '../../userNameContext'
import PinConfirm from '../../login/pinConfirm'
import CreatePostElement from '../../createPost/createPostElement'


const FeedBroad = () => {   
    const { userId } = useUserName();
    const myUserId = userId;
    const [authorization, setAuthorization] = useState(true)
   
    const userServiceUser = new socialApi.User();
    const userServiceBookmark = new socialApi.BookmarkList();
    const userServicePost = new socialApi.PostList();
    const userServiceFollow = new socialApi.Follow();
    
    const [onUserFeed, setOnUserFeed] = useState(true);
    // Modal edit
    const [showModal, setShowModal] = useState(false);
    const [postId, setPostId] = useState("");

    const pathUrl = useLocation().pathname
    const navigate = useNavigate()
    const userIdParam = useParams()
    const userIdProfile = userIdParam.userId;


    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingPost, setLoadingPost] = useState(true);
    const [postData, setPostData] = useState([])
    const [bookmarkData, setBookmarkData] = useState([])
    const [hasMore, setHasMore] = useState(true);
    const [indexPost, setIndexPost] = useState(2);
    const [index, setIndex] = useState(2);
    const itemPerFetch = 10 // หลังบ้านส่งมาทีละ 10

    const [isFollow, setIsFollow]  = useState(false);
    const [numberOfFollowing, setNumberOfFollowing] = useState([])

    useEffect(() => {
        if (pathUrl.includes('profilelist')) {
            setOnUserFeed(true);
        } else {
            setOnUserFeed(false);
        }
    }, [pathUrl]); 
    
    useEffect(() => {
      setAuthorization(true)
      const fetchData = async () => {
          setLoading(true)
          try {
            const userData = await userServiceUser.getSearchUserData(userIdProfile)               
            setData({
                  user: userData?.data?.user,
            });                                 
            setIsFollow(userData?.data?.user.followers?.map(userData => userData.userId).indexOf(myUserId) !== -1 ?  true : false)              
            setNumberOfFollowing(userData?.data?.user?.followers?.length)
         
         } catch (error) {
            if(error.response.status === 401){
               setAuthorization(false)
            } else {
               console.log(error)
            }            
          } finally {
            setLoading(false);
          }
      }      
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[userIdProfile]); 
      
      
      useEffect(() => {
         setAuthorization(true)
         setLoadingPost(true)
         if (onUserFeed) {
            setHasMore(true)
            setIndexPost(2)
            userServicePost.getPostOfUser(userIdProfile, 1)
               .then(response => {
                  setPostData(response.data.postList);
                  response.data.postList?.length < itemPerFetch ? setHasMore(false) : setHasMore(true)
                  setLoadingPost(false);
               })
               .catch(error => {
                  if(error.response.status === 401){
                     setAuthorization(false)
                  } else {
                     console.log(error)
                  }           
               });
         } else {
            if(myUserId !== userIdProfile){
               navigate("/feed/all")
            }
            setHasMore(true)
            setIndex(2)
            userServiceBookmark.getBookmarkPost(userIdProfile, 1)
               .then(response => {
                  setBookmarkData(response.data.postList);
                  response.data.postList?.length < itemPerFetch ? setHasMore(false) : setHasMore(true)
                  setLoadingPost(false);
               })
               .catch(error => {
                  if(error.response.status === 401){
                     setAuthorization(false)
                  } else {
                     console.log(error)
                  }           
               });
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [onUserFeed]);
      
      const fetchMoreData = () => {
         setAuthorization(true)
         if(onUserFeed) {
            userServicePost.getPostOfUser(userId, indexPost)
            .then(res => {
               if (res.data.postList && res.data.postList.length > 0) {
                  setPostData(prevItems => [...prevItems, ...res.data.postList]);
                  setHasMore(true);
               } else {
                  setHasMore(false);
               }
            })
            .catch(error => {
               if(error.response.status === 401){
                  setAuthorization(false)
               } else {
                  console.log(error)
               }           
            });
            setIndexPost(prevIndex => prevIndex + 1);
         } else {
            userServiceBookmark.getBookmarkPost(userIdProfile, index)
            .then(res => {
               if (res.data.postList && res.data.postList.length > 0) {
                  setBookmarkData(prevItems => [...prevItems, ...res.data.postList]);
                  setHasMore(true);
               } else {
                  setHasMore(false);
               }
            })
            .catch(error => {               
               if(error.response.status === 401){
                  setAuthorization(false)
               } else {
                  console.log(error)
               }           
            });
      
         setIndex(prevIndex => prevIndex + 1);
         }

      };

      const onColoseModal = () => {
         setShowModal(false)
      }
         
      const showEditModal = (userID, postId) => {
         setShowModal(true)
         setPostId(postId)
      }
      
      const onDeleteComment = () => {
         onColoseModal()
         return (
            Swal.fire({
                  icon: "question",
                  iconColor: "#1567B4",
                  imageWidth: 100,
                  html: `
                     <label className='swal2-title-custom text-center'>คุณแน่ใจใช่ไหมที่จะลบ</label>
                  `,
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: "ลบ",
                  cancelButtonText: "ยกเลิก",
                  reverseButtons: true,
                  customClass: {
                     cancelButton: 'swal-btn-gray-noborder',
                     confirmButton: 'swal-btn-primary-noborder',
                     container: 'swal2-container-custom',
                     content: 'swal2-content-custom',
                  }})
         )
      }
    
      const customModalStyles = {
         content: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: '80%',
            maxWidth: '200px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '0px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',            
         },
         overlay: {
            zIndex: 2000,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
         },
      }

      const followPeople = async (follow) => {  
         try {
           const data = {
             "follower": myUserId,
             "following": follow,
           };
           await userServiceFollow.followUser(data) 
           if(isFollow) {
            setNumberOfFollowing((prev)=> prev - 1)
           } else {
            setNumberOfFollowing((prev)=> prev + 1)
           }
           setIsFollow(!isFollow)
         } catch (error) {
           if(error.response.status === 401){
               setAuthorization(false)
           } else {
               console.log(error)
           }   
         } 
       };       

    return (
      authorization ? 
        <motion.div
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
        >
            <HeaderOther 
               headerText={data.user?.userName}
               prevUrl="/feed/all"    
            />   
               {loading ? 
                  <Loading />
                  :
                  <>
                     <Container>
                                 <Col className="m-0 p-0">
                                    <div id="profileContainer">                                          
                                       <Card>
                                          <Card.Body className="p-0">
                                                            <div id="profilePage" className="profile-header">
                                                               <div className="user-detail text-center mb-3 mt-4">
                                                                  <div className="profileImg">
                                                                     {
                                                                        data.user?.userImage?.startsWith("profile") ? 
                                                                           <img loading="lazy" src={process.env.PUBLIC_URL + `/images/profile/${data.user?.userImage}.png`} alt="profile-img1" className="" width="100px" height="100px"/>
                                                                              :
                                                                           <img loading="lazy" src={`${data.user?.userImage ?(data.user?.userImage).replace(/ /g, "%20"): process.env.PUBLIC_URL + `/images/profile/profile0${1}.png`} `} alt="profile-img1" className="" width="100px" height="100px"/>
                                                                     }
                                                                     
                                                                  </div>
                                                                  <div className="profile-detail mt-3">
                                                                        {numberOfFollowing > 0 ?
                                                                           <h3>{`ผู้ติดตาม ${numberOfFollowing}`}</h3>:
                                                                           <h3>{`ยังไม่มีผู้ติดตาม`}</h3>
                                                                        }
                                                                        <div className='editText mt-2 mb-3'>
                                                                           {
                                                                              data.user?.description ?
                                                                              <span className='me-2'>{data.user?.description}</span>:
                                                                              <span className='me-2'>ลองเขียนประวัติเพื่อช่วยให้คนอื่นค้นพบคุณง่ายขึ้น</span>
                                                                           }                                                     
                                                                           {
                                                                              myUserId === userIdProfile &&
                                                                              <NavLink to={`/dashboard/app/profilelist/edit/${userIdProfile}/`}>
                                                                                 <img 
                                                                                    src={process.env.PUBLIC_URL + `/images/icon/penEditSmall.svg`}
                                                                                    alt="editIcon"                                          
                                                                                 />
                                                                              </NavLink>
                                                                           }             
                                                                        </div>
                                                                        {
                                                                              (myUserId !== userIdProfile) &&
                                                                              <button
                                                                              className={`followBtn ${isFollow ? "unfollow" : "follow"}`}
                                                                              onClick={() => followPeople(userIdProfile)}
                                                                            >
                                                                              {isFollow ? "เลิกติดตาม" : "ติดตาม"}
                                                                            </button>
                                                                        }
                                                                        {
                                                                           myUserId === userIdProfile &&
                                                                           <NavLink to={`/dashboard/app/profilelist/edit/${userIdProfile}/`}>
                                                                              <button className='user-edit'>ข้อมูลส่วนตัว</button>
                                                                           </NavLink>
                                                                        }

                                                                  </div>
                                                               </div>                      
                                                               <Navigation
                                                                  userId={userIdProfile}
                                                                  canSeeBookmark={myUserId === userIdProfile}
                                                               />
                                                            </div>
                                          </Card.Body>
                                       </Card>
                                       {loadingPost ?
                                       <Loading />                                 
                                       : 
                                       <div className='postContainer'>
                                                      {
                                                         onUserFeed?
                                                            postData?.length ?    
                                                                  <InfiniteScroll
                                                                     dataLength={postData.length}
                                                                     next={fetchMoreData}
                                                                     hasMore={hasMore}
                                                                     loader={<Loading />}
                                                                  >            
                                                                     {myUserId === userIdProfile &&
                                                                        <CreatePostElement 
                                                                           myUserId={myUserId}
                                                                           userProfileName={data.user?.userName}
                                                                           userProfileImage={data.user?.userImage}
                                                                     />}
                                                                     <PostList 
                                                                        feedPost={postData}
                                                                        showModal={showEditModal}
                                                                     />
                                                                  </InfiniteScroll>   
                                                                     :
                                                                  <UserEmpty 
                                                                     img="haveNoPost"
                                                                     wording1="คุณยังไม่มีโพสต์"
                                                                     wording2="กดบวก เพื่อเพิ่มโพสต์ของคุณ"
                                                                  />
                                                            :              
                                                            bookmarkData?.length && myUserId === userIdProfile?  
                                                                  <InfiniteScroll
                                                                     dataLength={bookmarkData.length}
                                                                     next={fetchMoreData}
                                                                     hasMore={hasMore}
                                                                     loader={<Loading />}
                                                                  >                
                                                                  <PostList 
                                                                     feedPost={bookmarkData}
                                                                     showModal={showEditModal}
                                                                  />       
                                                                  </InfiniteScroll>   
                                                                     : 
                                                                  <UserEmpty 
                                                                     img="bookmarkPostEmpty"
                                                                     wording1="คุณยังไม่มีโพสต์ที่ถูกใจ"
                                                                  />                                           
                                                      }
                                       </div> 
                                       }                   
                                       <EditModal 
                                          showModal={showModal}
                                                      customModalStyles={customModalStyles}
                                                      onColoseModal={onColoseModal}
                                                      onDeleteComment={onDeleteComment}
                                                      editPost={true}
                                          postId={postId}
                                       />   
                                    </div>   
                                 </Col>
                     </Container>  
                     {myUserId === userIdProfile && <CreatePostBtn />}
                  </>
               }
         <BotBar />
        </motion.div> 
         : 
        <PinConfirm />                                     
    )
}

export default FeedBroad