import React, { useState, useEffect }  from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import HeaderOther from '../../helper/HeaderStyle/headerOther';
import './user-profile-edit.scss';
import { motion } from 'framer-motion'
import socialApi from '../../../../service/services'
import Resizer from "react-image-file-resizer";
import { useUserName } from '../../userNameContext';
import Loading from '../../helper/loading';
import PinConfirm from '../../login/pinConfirm';
import {Container} from 'react-bootstrap'
import UserProfileInfo from './userProfileInfo';

const UserProfileEdit = ()=> {
    const { userId } = useUserName();
    const [authorization, setAuthorization] = useState(true)
    const userServiceUser = new socialApi.User();
    const userServicePost = new socialApi.PostList();
    const headerText = 'ข้อมูลส่วนตัว';
    const [inputValue, setInputValue] = useState();
    const [userImage, setUserImage] = useState(null);
    const [oldUserImage, setOldUserImage] = useState()
    const [loading, setLoading] = useState(true)
    const [text, setText] = useState('');
    const [textCount, setTextCount] = useState(0);
    const navigate = useNavigate()
    const paramUserId = useParams().postId
    const [onProcess, setOnProcess] = useState(false)

    useEffect(() => {
        if(paramUserId !== userId) {
            navigate("/feed/all")
        }
        setLoading(true);
    
        userServiceUser.getSearchUserData(userId)
          .then((response) => {
            if (response.status === 200) {
              setInputValue(response.data.user.userName);
              setText(response.data.user.description);
              setTextCount(response.data.user.description.length);
              setUserImage(response.data.user.userImage);
              setOldUserImage(response.data.user.userImage);
            }
          })
          .catch((error) => {
            if(error.response.status === 401){
                setAuthorization(false)
             } else {
                console.log(error)
             }    
            // PinConfirm
          })
          .finally(() => {
            setLoading(false);
          });
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [userId]);

    const upLoadUserImage = (event)=> {
        const files = event.target.files;
        if (files.length > 0) {
            setUserImage(files[0]);
        }
    }

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleClearClick = () => {
      setInputValue('');
    };

    const handleChange = (event) => {
        const newText = event.target.value;
        setText(newText);
        setTextCount(newText.length);
    };

    const saveChangeProfile = async () => {  
        setOnProcess(true)
        const pathOldImage = oldUserImage.substring(oldUserImage.lastIndexOf('/') + 1);
        let newUploadedImage = ""

        if(typeof userImage === 'string') {
            newUploadedImage = oldUserImage 
        } else {
            await userServicePost.deleteImage(pathOldImage, "userImage");
            newUploadedImage  = await uploadFile(userImage);
        }

        try {
          const data = {
            "userId": userId,
            "userName": inputValue,
            "userImage": newUploadedImage,
            "description": text
          };
          userServiceUser.editUserData(data).then((response)=>{
            if(response.status === 200){
                navigate(-1);
            }
          })
        } catch (error) {
          console.error('Error posting data:', error);
        } finally {
            setOnProcess(false)
        }
    };
    
    const uploadFile = async (files) => {
        try {
            const resize = await resizeImg(files)
            const response = await userServicePost.uploadImage(resize, "userImage");
            let res = response.data.imageUpload;
            return res;
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error; 
        }
    };

    const resizeImg = (file) => {
        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                450,
                450,
                'JPEG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file',
                450, 
                450,
                false,
                () => {
                    reject(new Error('Image resizing failed.'));
                }
            );
        });
    };

    return (authorization ?
            <motion.div
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                exit={{
                    opacity: 0
                }}
            >
                <HeaderOther 
                    headerText={headerText}
                    prevUrl={`/dashboard/app/profilelist/${userId}`}
                />
                    {loading ?
                    <Loading /> :
                    <Container>
                        <div id='userProfileEdit' className='bg-white d-flex flex-column align-items-center'>
                            <div className='userProfileImg mt-5'>
                                {}
                                {
                                (typeof(userImage)=== "object") ? (
                                    <img 
                                        width="150px" height="150px"
                                        style={{borderRadius: "50%"}}
                                        src={URL.createObjectURL(userImage)} alt="UploadedUserImage" />
                                    ) :
                                    userImage.startsWith("profile") ?
                                        <img style={{borderRadius: "50%"}} src={process.env.PUBLIC_URL + `/images/profile/${userImage}.png`} alt="profile" 
                                            width="150px" height="150px"
                                        />:
                                        <img style={{borderRadius: "50%"}} src={userImage} alt="profile" 
                                            width="150px" height="150px"
                                        />
                                }                        
                                <>
                                <label htmlFor="fileInput" className='uploadIcon'>      
                                    <img src={process.env.PUBLIC_URL + `/images/icon/uploadImageProfile.svg`} alt="uploadIcon" />              
                                    <input
                                    type="file"
                                    accept="image/*"
                                    id="fileInput"
                                    onChange={upLoadUserImage}
                                    style={{ display: 'none' }}
                                    />
                                </label>          
                                </>
                            </div>

                            <div className='creatUserName'>
                            <input 
                                className='mt-3'
                                placeholder='ตั้งชื่อเล่น'
                                type="text"
                                onChange={handleInputChange}
                                value={inputValue || ""}
                            />                 
                            <img 
                                className="clearInput" 
                                src={process.env.PUBLIC_URL + `/images/icon/clearInput.svg`}
                                onClick={handleClearClick}       
                                alt="claerInputBtn"           
                            />
                            
                            </div>

                            <div className='editProfile mt-4 d-flex flex-column align-items-center'>
                                <textarea                   
                                    value={text}
                                    maxLength={80}
                                    onChange={handleChange}
                                    placeholder='ลองเขียนประวัติเพื่อช่วยให้คนอื่นค้นพบคุณง่ายขึ้น'
                                />
                                <p className='textCount'>({textCount}/80)</p>
                            </div>                    
                            <button 
                                disabled={onProcess}
                                className='saveChangeBtn mt-5' onClick={saveChangeProfile}>บันทึก</button>
                            <UserProfileInfo />
                        </div>
                    </Container>
                    }          
            </motion.div> 
                :
            <PinConfirm />  )
}

export default UserProfileEdit