import React , {useState} from 'react';
// import { useNavigate } from "react-router-dom";
import './login.scss'
import LoginPage from './loginPage';
import OtpPage from './otpPage';
import PinCreatePage from './pinCreatePage';
import PinConfirmPage from './pinConfirmPage';

const Login = (props) => {
    const [stateLogin, setStateLogin] = useState("login")
    const [loginRef, setLoginRef] = useState("")

    const handleStateLoginChange = (loginState) => {
        setStateLogin(loginState)
    }

    const receiveDataFromLogin = (loginRef) => {
        setLoginRef(loginRef)
    }
    
    return (
      <div id='login'>
        {stateLogin === "login" &&
            <LoginPage 
                handleStateLoginChange={handleStateLoginChange}
                receiveDataFromLogin={receiveDataFromLogin}
            />}
        {stateLogin === "otp" &&
            <OtpPage 
                handleStateLoginChange={handleStateLoginChange}
                loginRef={loginRef}
            />
        }
        {stateLogin === "pinCreate" &&
            <PinCreatePage
                handleStateLoginChange={handleStateLoginChange}
                checkCompleteLogin={props.checkCompleteLogin}
            />
        }
        {stateLogin === "pinConfirm" &&
            <PinConfirmPage
                handleStateLoginChange={handleStateLoginChange}
                checkCompleteLogin={props.checkCompleteLogin}
            />
        }
      </div>

    )
}

export default Login