import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import './headerOtehr.scss';

const HeaderOther = (props) => {
  const prevUrl = props.prevUrl || ""
  const headerText = props.headerText;
  const navigation = useNavigate();
  const backPage = ()=> {
    navigation(-1)
  }
  return (
      <div id="headerOther"className="iq-top-navbar bg-dark d-flex flex-row justify-content-between align-items-center p-4">
          {prevUrl ?
          <NavLink to={prevUrl}>
            <img
                src={process.env.PUBLIC_URL + `/images/icon/leftArrow.svg`}
                className="leftArrow"
                alt="user"
                loading="lazy"
            />                       
          </NavLink>
          : <img
              src={process.env.PUBLIC_URL + `/images/icon/leftArrow.svg`}
              className="leftArrow"
              alt="user"
              loading="lazy"
              onClick={backPage}
          />        
          }
          <h2 className="text-white">{headerText}</h2>
          <div className="ms-2"></div>
      </div>
  );
};

export default HeaderOther;
