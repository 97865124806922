import React from 'react';
import { NavLink } from "react-router-dom";
import './postListTextHelper.scss'

const HashtagText = ({ text, hashtags }) => {
    const wordsArray = text.split(/(\s+|#[^\s#]+)/);
    
    const applyBlueText = (word, index) => {
      const isHashtag = word.startsWith('#') && word.length > 1;
      let wordToSearch
      if(isHashtag) wordToSearch = word.replace('#', '%23')
      
      return (
        isHashtag? 
        <NavLink key={index} to={`/feed/search/popular/${wordToSearch}`}>
            <span className='hashtag'>
                {word}{' '}
            </span>
        </NavLink>
            :
        <span key={index}>
            {word}{' '}
        </span>
      );
    };
  
    return <div id="hashtagList">{wordsArray.map((word, index) => applyBlueText(word, index))}</div>;
  };
export default HashtagText