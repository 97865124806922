
import React from 'react';
import { NavLink, useParams } from "react-router-dom";
import './searchPageNavigation.scss';

const Navigation = () => {
    const search= useParams().search;
    let newSearch = search
    if(search.includes("#")){
        newSearch = encodeURIComponent(search)
    }
    const linkPath = (searchType) => `/feed/search/${searchType}/${newSearch}`;

        return (
            <div id="navigationSearch">
                    <ul className='scrollable-list'>
                        <li>
                            <NavLink className='d-flex justify-content-center align-item-center' to={linkPath("popular")} >
                                โพสต์ยอดนิยม
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className='d-flex justify-content-center align-item-center' to={linkPath("user")} >
                                ผู้ใช้
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className='d-flex justify-content-center align-item-center' to={linkPath("hashtag")} >
                                แฮชแท็ก
                            </NavLink>
                        </li>                        
                    </ul>
            </div>
        )    
}

export default Navigation;