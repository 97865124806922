// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#searchRecommend {
  background-color: #fff;
  min-height: 90vh;
}
#searchRecommend .header {
  height: 20%;
  padding: 15px;
  border-bottom: 3px solid #F4F4F8;
}
#searchRecommend .header p {
  margin: 0;
}
#searchRecommend .header .huge {
  color: #0F0F0F;
}
#searchRecommend .header .small {
  cursor: pointer;
  color: #808080;
}
#searchRecommend .historyAll {
  height: 100%;
}
#searchRecommend .historyAll .historyItem {
  height: 10%;
  padding: 10px 20px;
}
#searchRecommend .historyAll p {
  color: #808080;
  margin: 0;
}
#searchRecommend .historyAll .delhistory {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/search/searchRecom.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,gBAAA;AACJ;AAAI;EACI,WAAA;EACA,aAAA;EACA,gCAAA;AAER;AADQ;EACI,SAAA;AAGZ;AADQ;EACI,cAAA;AAGZ;AADQ;EACI,eAAA;EACA,cAAA;AAGZ;AAAI;EACI,YAAA;AAER;AADQ;EACI,WAAA;EACA,kBAAA;AAGZ;AADQ;EACI,cAAA;EACA,SAAA;AAGZ;AADQ;EACI,eAAA;AAGZ","sourcesContent":["#searchRecommend {\n    background-color: #fff;\n    min-height: 90vh;    \n    .header {\n        height: 20%;\n        padding: 15px;\n        border-bottom: 3px solid #F4F4F8;\n        p {\n            margin: 0;\n        }\n        .huge {\n            color: #0F0F0F;\n        }\n        .small {\n            cursor: pointer;\n            color: #808080;\n        }\n    }\n    .historyAll {\n        height: 100%;\n        .historyItem {        \n            height: 10%;\n            padding: 10px 20px;\n        }\n        p {\n            color: #808080;\n            margin: 0;\n        }\n        .delhistory {\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
