// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#termContainer .termHeader {
  height: 10%;
  width: 100%;
}
#termContainer h2 {
  font-family: "kanit";
}
#termContainer .termDescriptionContainer {
  overflow: scroll;
  overflow-x: hidden;
  padding: 0px 20px;
  height: 100%;
  width: 100%;
}
#termContainer .termDescriptionContainer .nextBtn {
  cursor: pointer;
  color: #FFFFFF;
  min-width: 360px;
  min-height: 48px;
  border-radius: 4px;
  padding: 12px 24px;
  transition: ease-in-out 0.2s;
  cursor: pointer;
  background-color: #1567B4;
  border: 1px solid #1567B4;
  margin-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/login/termOfService/term.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,WAAA;AAAR;AAEI;EACI,oBAAA;AAAR;AAEI;EACQ,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAAZ;AACY;EACI,eAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,4BAAA;EACA,eAAA;EACA,yBAAA;EACA,yBAAA;EACA,mBAAA;AAChB","sourcesContent":["#termContainer {\n    .termHeader {\n        height: 10%;\n        width: 100%;\n    }\n    h2 {\n        font-family: 'kanit';\n    }\n    .termDescriptionContainer {\n            overflow: scroll;\n            overflow-x: hidden;\n            padding: 0px 20px;\n            height: 100%;   \n            width: 100%;\n            .nextBtn {\n                cursor: pointer;\n                color: #FFFFFF;\n                min-width: 360px;\n                min-height: 48px;\n                border-radius: 4px;\n                padding: 12px 24px;\n                transition: ease-in-out 0.2s;\n                cursor: pointer;\n                background-color: #1567B4;\n                border: 1px solid #1567B4;    \n                margin-bottom: 50px;\n            }              \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
