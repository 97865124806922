// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#headerOther h2 {
  font-family: "kanit";
}
#headerOther .leftArrow {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/helper/HeaderStyle/headerOtehr.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;AAAR;AAEI;EACI,eAAA;AAAR","sourcesContent":["#headerOther {\n    h2 {\n        font-family: 'kanit';\n    }\n    .leftArrow {\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
