// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loginHeader {
  background-color: #000000;
}
#loginHeader h2 {
  font-family: "kanit";
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/login/loginHeader.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;AAAI;EACI,oBAAA;AAER","sourcesContent":["#loginHeader {\n    background-color: #000000;\n    h2 {\n        font-family: 'kanit';\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
