// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#navigation-tab {
  background-color: #FFFFFF;
  position: sticky;
}
#navigation-tab .scrollable-list {
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 0;
  padding: 0;
  border-bottom: 1px solid #f2f4f7;
}
#navigation-tab .scrollable-list li {
  display: inline-block;
  margin-right: 20px;
}
#navigation-tab .scrollable-list li a {
  display: block;
  padding: 14px 10px;
  text-decoration: none;
  font-family: "kanit";
  color: #808080;
  border-bottom: 5px solid transparent;
}
#navigation-tab .scrollable-list li a.active {
  font-family: "kanit";
  color: #1567B4;
  border-bottom: 5px solid #1567B4;
  transition: ease-in-out 0.3s;
}
#navigation-tab .scrollable-list::-webkit-scrollbar {
  width: 0;
  /* Hide horizontal scrollbar */
}
#navigation-tab .scrollable-list::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Optional: Hide scrollbar thumb */
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/helper/navigation.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,gBAAA;AACJ;AAAI;EACI,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;EACA,gCAAA;AAER;AAAQ;EACI,qBAAA;EACA,kBAAA;AAEZ;AAAY;EACI,cAAA;EACA,kBAAA;EACA,qBAAA;EACA,oBAAA;EACA,cAAA;EACA,oCAAA;AAEhB;AACY;EACI,oBAAA;EACA,cAAA;EACA,gCAAA;EACA,4BAAA;AAChB;AAII;EACI,QAAA;EAAU,8BAAA;AADlB;AAII;EACI,6BAAA;EAA+B,mCAAA;AADvC","sourcesContent":["#navigation-tab {\n    background-color: #FFFFFF;\n    position: sticky;    \n    .scrollable-list {\n        white-space: nowrap;\n        overflow-x: auto;\n        margin-bottom: 0;\n        padding: 0;\n        border-bottom: 1px solid #f2f4f7;\n        \n        li {\n            display: inline-block;\n            margin-right: 20px;\n            \n            a {                \n                display: block;\n                padding: 14px 10px;\n                text-decoration: none;\n                font-family: 'kanit';\n                color: #808080;\n                border-bottom: 5px solid transparent;\n            }\n\n            a.active {\n                font-family: 'kanit';\n                color: #1567B4;\n                border-bottom: 5px solid #1567B4;\n                transition: ease-in-out 0.3s;\n            }\n        }\n    }\n\n    .scrollable-list::-webkit-scrollbar {\n        width: 0; /* Hide horizontal scrollbar */\n    }\n\n    .scrollable-list::-webkit-scrollbar-thumb {\n        background-color: transparent; /* Optional: Hide scrollbar thumb */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
