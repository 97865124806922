import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Container} from 'react-bootstrap'
import './pinPage.scss'
import PinInput from "react-otp-input";
import loginApi from '../../../service/serviceLogin';
import Loading from '../helper/loading';
import { Link } from 'react-router-dom';

const PinConfirm = () => {
    const loginPIN = new loginApi.PIN()
    const processLogin = new loginApi.ProcessLogin()
    const userNameLogin = localStorage.getItem('userName')
    const navigate = useNavigate()
    const [pin, setPin] = useState('');
    const [wrongPin , setWrongPin] = useState(false)
    const [loading, setLoading] = useState(false)

    
    const handlePinChange = (inputPin) => {
        setPin(inputPin);
        if (inputPin.length === 6) {     
            confirmPin(inputPin)
        } else {
            setWrongPin(false)
        }
    };


    const confirmPin = async (inputPin) => {
        
        setLoading(true)
        const data = {
            "password": inputPin
        }
        try {
            let checkLogin = await processLogin.checkProcessLogin(userNameLogin)


            const res = await loginPIN.authPIN(data)
            if (res.data.status) {
                setLoading(true)
                localStorage.setItem("authToken", res.data.token);      
                if(!checkLogin.data.pdpa){
                    navigate("/pdpa")
                } else {
                    window.location.reload()  
                }                            
            } else {
                setLoading(false)
                setWrongPin(true);
                setPin('');
                return;
            }            
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div id="pinPage">
            <div className="loginHeader d-flex flex-row p-4">                
                    <h2 className="text-white ms-3">ยืนยันรหัส PIN</h2>
            </div>
            <Container className='d-flex flex-column align-items-center pt-5'>
                    <div className='pinTextDescription d-flex flex-column align-items-center'>
                            <div className='mb-5 d-flex flex-column align-items-center'>
                                <p>ยืนยันรหัส PIN</p>
                                <p>Confirm your number PIN </p>                                
                                {wrongPin && <p className='textRed m-0'>รหัส PIN ไม่ถูกต้อง</p>}
                            </div>    
                        {
                            loading ?
                            <Loading />
                                :
                            <><PinInput
                                value={pin}
                                onChange={handlePinChange}
                                numInputs={6}
                                renderSeparator={<span style={{ width: "8px" }}></span>}
                                inputType="number"
                                renderInput={(props, index) => {
                                return (
                                <input
                                    {...props}
                                    value=""
                                    style={{
                                        border: `2px solid  ${wrongPin ? "red":
                                        pin?.length === index ? "white" : "transparent"}`,
                                        borderRadius: "50%",
                                        width: "30px",
                                        height: "30px",
                                        fontSize: "12px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "transparent",
                                        backgroundColor: props.value ? "#ffffff" : "#4D4D4D",
                                    }}
                                />
                            )}}
                                caretRender={() => null}
                                isInputNum={true}
                                shouldAutoFocus={true}
                            />
                            <Link to="/reset_pin">
                                <p className='mt-5'>ลืมรหัส PIN</p>
                            </Link>
                            </>
                        }                    
         
                    </div>
            </Container>
        </div>
    )
}

export default PinConfirm