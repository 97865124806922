import React from 'react';
import './user-profile-empty.scss';

const UserEmpty = (props) => {
   
    return (
        <>
            <div id="userEmpty" className='d-flex flex-column align-items-center mt-4'>    
                <img 
                    className='mb-5'
                    src={process.env.PUBLIC_URL + `/images/icon/${props.img}.svg`}
                    width="70px"
                    height="70px"
                    alt="emptyFeed"
                />
                <div className='d-flex flex-column align-items-center'>
                    <p>{props.wording1}</p>
                    <p>{props.wording2 || ""}</p>
                </div>                                                                        
            </div>
        </>
    )
}

export default UserEmpty