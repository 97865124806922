import React , {useState, useEffect} from 'react';
import { Container} from 'react-bootstrap'
import './loginPage.scss'
import loginApi from '../../../service/serviceLogin';
import { useUserName } from '../userNameContext';

const LoginPage = (props) => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");

    
    const { setUserName } = useUserName()
    const [inputValue, setInputValue] = useState("");
    const loginOTP = new loginApi.OTP()

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const clickLogin = () => {
        enterAccountForGetOTP()        
    }
    
    const enterAccountForGetOTP = () => {
        let data = {
            "msisdn": inputValue,
        }
        localStorage.setItem("userName", inputValue)
        try {
            loginOTP.getOTP(data).then((res)=>{     
                setUserName(inputValue)        
                props.receiveDataFromLogin(res.data.reference)
            })
        } catch (error) {
            console.log(error)
        }   finally {
            props.handleStateLoginChange("otp")
        }
    }
        
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter' && inputValue?.length > 0)  {
                e.preventDefault(); 
                enterAccountForGetOTP()
            }
        }; 
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    return (
        <div id="loginPage">
            <div className='changeLang'>
                {/* <p>THAI / ENG</p> */}
            </div>
            <Container className='d-flex flex-column align-items-center pt-5'>
                    <img 
                        className='mb-5'
                        src={process.env.PUBLIC_URL+'/images/login/svmLogo.png'}
                        alt="svmLogo"
                    />
                    <div className='creatUserName d-flex flex-row justify-content-center'>
                    <input 
                        className='mt-3 mb-3'
                        placeholder='กรุณากรอกหมายเลขโทรศัพท์หรืออีเมล'
                        type="text"
                        onChange={handleInputChange}
                        value={inputValue || ""}
                        autoFocus
                    />                                   
                    </div>
                    <button 
                        onClick={clickLogin}
                        disabled={inputValue?.length === 0}
                        className={`loginBtn loginBtn${inputValue?.length > 0 ? "Enable" : "Disable"}`}>
                        เข้าสู่ระบบ
                    </button>
                    <div className='d-flex flex-column align-items-center mb-4 mt-4'>
                        <p className='textContract'>หากมีคำถาม หรือต้องการความช่วยเหลือ </p>
                        <p className='textContract'>กรุณาติดต่อ 081 442 6888</p>
                    </div>
                    <div className='d-flex flex-column align-items-center'>
                        <p className='textAppVersion'>version 3.0.2</p>
                        <p className='textAppVersion'>2024 Silverman.app All rights reserved</p>
                    </div>
            </Container>
        </div>
    )
}

export default LoginPage