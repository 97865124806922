import React, {useState} from 'react'
import { Offcanvas } from 'react-bootstrap'
import icon8 from '../../../assets/images/icon/08.png'
import { Link } from 'react-router-dom'

import {
    LineShareButton,
    LineIcon,
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
  } from "react-share";


const ShareOffcanvas = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const REST_API_URL = "https://silvermansociety.app/";
    const url = `${REST_API_URL}${props.url}`

    return (
        <>
            <div className="d-flex align-items-center feather-icon">
                <Link to="#" onClick={handleShow} className="d-flex align-items-center">
                    <img 
                        src={process.env.PUBLIC_URL + `/images/icon/share.svg`} alt="shareIcon"
                    />
                </Link>  
            </div>
            <Offcanvas show={show} onHide={handleClose}  placement='bottom'>
                <Offcanvas.Header closeButton>
                   <Offcanvas.Title>Share</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex flex-wrap align-items-center">
                        <FacebookShareButton className='me-4' url={url}>
                            <img src={icon8} className="img-fluid rounded mb-2" alt="" width="35px" height="35px"/>
                            <p>Facebook</p>
                        </FacebookShareButton>

                        <LineShareButton className='me-5' url={url}>
                            <LineIcon className="img-fluid rounded mb-2" alt="" size={35} />
                            <p>Line</p>
                        </LineShareButton>

                        <EmailShareButton className='me-4' url={url}>
                            <EmailIcon className="img-fluid rounded mb-2" alt="" size={35} />
                            <p>E-mail</p>
                        </EmailShareButton>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default ShareOffcanvas;

