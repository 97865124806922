import React, {useState} from 'react';
import { Container} from 'react-bootstrap'

import './term.scss';

const TermMarketingPurpose = (props) => {
    const [accept, setAccept] = useState(true);

    const handlePdpaProcess = ()=> {
        props.handleAcceptMarketingPurpose(accept)
        props.handleStepTerm(4)
    }

    return (
            <div id="termContainer">
                  <div className="iq-top-navbar bg-dark d-flex flex-row justify-content-center align-items-center p-4">                    
                        <h2 className="text-white ms-3">จัดการข้อมูลส่วนตัว</h2>
                  </div>
                  <Container>
                  <div className='termDescriptionContainer'>       
                        <h5 className='text-center m-4'>ความยินยอมเพื่อวัตถุประสงค์ทางการตลาด</h5>       
                        <p>เราให้ความสำคัญกับความเป็นส่วนตัวของคุณอยู่เสมอ</p>
                        <p>เราทำการวิจัยและวิเคราะห์ข้อมูลส่วนบุคคลของคุณเพื่อประโยชน์สูงสุดในการพัฒนาผลิตภัณฑ์และบริการที่ตอบสนองความต้องการของคุณอย่างแท้จริง รวมทั้งติดต่อคุณเพื่อเสนอผลิตภัณฑ์ บริการ และสิทธิประโยชน์ที่เหมาะสมแก่คุณโดยเฉพาะ</p>
                        <p>กรุณากด "ยินยอม" หากคุณตกลงให้เราเก็บรวบรวมและ ใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ทางการตลาดข้างต้น</p>
                        <p>โปรดอ่านเพิ่มเติมเกี่ยวกับประกาศนโยบายความเป็นส่วนตัวของเราอย่างละเอียด เพื่อเข้าใจถึงวิธีการที่เราเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของคุณและสิทธิของคุณที่เว็บไซต์ของ Silverman www.silverman.app</p>
                        <p>กรณีคุณไม่ให้ความยินยอมหรือขอถอนความยินยอมในภายหน้า คุณอาจจะพลาดโอกาสในการรับข้อเสนอผลิตภัณฑ์ บริการ และสิทธิประโยชน์ที่เหมาะสมกับความต้องการของคุณโดยเฉพาะจากเรา</p>
                        <p>ยินยอมให้เก็บข้อมูลเพื่อวัตถุประสงค์การตลาดได้</p>
                        <div className='d-flex justify-content-around'>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name="flexRadioDefault" 
                                    id="flexRadioDefault1"
                                    checked={accept === true}
                                    onChange={() => setAccept(true)}
                                    />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    ยินยอม
                                </label>
                                </div>
                            <div className="form-check">
                                <input 
                                className="form-check-input" 
                                type="radio" 
                                name="flexRadioDefault" 
                                id="flexRadioDefault2"
                                checked={accept === false}
                                onChange={() => setAccept(false)}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    ปฏิเสธ
                                </label>
                            </div>
                        </div>
                        <div className='botTextContainer'>
                            <p className='bottomText mt-2'>คุณอาจจะพลาด โอกาสในการรับข้อเสนอผลิตภัณฑ์บริการ และสิทธิประโยชน์ที่เหมาะสมกับความต้องการของคุณโดยเฉพาะจาก Silverman ในกรณีที่คุณไม่ให้ความยินยอมหรือขอถอนความยินยอม</p>
                        </div>    
                        <div className='d-flex justify-content-center mt-5'>                 
                            <button className='nextBtn' onClick={()=>handlePdpaProcess()}>ถัดไป</button>
                        </div>
                  </div>
                  </Container>
            </div>
    )
}

export default TermMarketingPurpose

 


