// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#navigationSearch {
  background-color: #FFFFFF;
  position: sticky;
  margin-bottom: 4px;
}
#navigationSearch .scrollable-list {
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 0;
  padding: 0;
  border-bottom: 1px solid #f2f4f7;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
#navigationSearch .scrollable-list ul {
  padding: 0;
  margin: 0;
}
#navigationSearch .scrollable-list li {
  display: inline-block;
}
#navigationSearch .scrollable-list li a {
  display: block;
  padding: 14px 10px;
  text-decoration: none;
  font-family: medium;
  color: #808080;
  border-bottom: 5px solid transparent;
}
#navigationSearch .scrollable-list li a.active {
  font-family: large;
  color: #1567B4;
  border-bottom: 5px solid #1567B4;
  transition: ease-in-out 0.3s;
}
#navigationSearch .scrollable-list::-webkit-scrollbar {
  width: 0;
  /* Hide horizontal scrollbar */
}
#navigationSearch .scrollable-list::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Optional: Hide scrollbar thumb */
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/search/searchPageNavigation.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAAI;EACI,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;EACA,gCAAA;EACA,aAAA;EACA,kCAAA;AAER;AADQ;EACI,UAAA;EACA,SAAA;AAGZ;AADQ;EACI,qBAAA;AAGZ;AADY;EACI,cAAA;EACA,kBAAA;EACA,qBAAA;EACA,mBAAA;EACA,cAAA;EACA,oCAAA;AAGhB;AAAY;EACI,kBAAA;EACA,cAAA;EACA,gCAAA;EACA,4BAAA;AAEhB;AAGI;EACI,QAAA;EAAU,8BAAA;AAAlB;AAGI;EACI,6BAAA;EAA+B,mCAAA;AAAvC","sourcesContent":["#navigationSearch {\n    background-color: #FFFFFF;\n    position: sticky;\n    margin-bottom: 4px;\n    .scrollable-list {\n        white-space: nowrap;\n        overflow-x: auto;\n        margin-bottom: 0;\n        padding: 0;\n        border-bottom: 1px solid #f2f4f7;\n        display: grid;\n        grid-template-columns: 1fr 1fr 1fr;\n        ul{\n            padding: 0;    \n            margin: 0;\n        }\n        li {\n            display: inline-block;            \n\n            a {\n                display: block;\n                padding: 14px 10px;\n                text-decoration: none;\n                font-family: medium;\n                color: #808080;\n                border-bottom: 5px solid transparent;\n            }\n\n            a.active {\n                font-family: large;\n                color: #1567B4;\n                border-bottom: 5px solid #1567B4;\n                transition: ease-in-out 0.3s;\n            }\n        }\n    }\n\n    .scrollable-list::-webkit-scrollbar {\n        width: 0; /* Hide horizontal scrollbar */\n    }\n\n    .scrollable-list::-webkit-scrollbar-thumb {\n        background-color: transparent; /* Optional: Hide scrollbar thumb */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
