import { BrowserRouter as Router } from 'react-router-dom';
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"
import './App.scss'

import RouteIndex from "./views/dashboard/routeIndex";

function App() {
  return (
    <div className="App">
    <Router>
      <RouteIndex />
    </Router>
  </div>
  );
}

export default App;
