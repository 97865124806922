
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import './user-profile-navigation.scss';


const Navigation = (props) => {
    const [activeFeed, setActiveFeed] = useState(true)
    const [activeBookmark, setActiveBookmark] = useState(false)
    const pathUrl = useLocation().pathname

    useEffect(() => {
        if (pathUrl.includes('profile')) {
            setActiveFeed(true);
            setActiveBookmark(false);
        } else {
            setActiveBookmark(true);
            setActiveFeed(false);
        }
    }, [pathUrl]); 

    return (
            <div id="navigation-tab" className='d-flex align-items-center justify-content-around list-inline p-0 m-0 ms-2 me-2'> 
                    <ul>
                        <li>
                            <NavLink to={`/dashboard/app/profilelist/${props.userId}`}>                                          
                                <img 
                                    src={process.env.PUBLIC_URL + `/images/icon/userFeed${activeFeed === true ? "Active": ""}.svg`}
                                    alt="userFeed"
                                />
                            </NavLink>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            {props.canSeeBookmark ?
                            <NavLink to={`/dashboard/app/bookmarklist/${props.userId}`}>                                          
                                <img 
                                    src={process.env.PUBLIC_URL + `/images/icon/userBookmark${activeBookmark === true ? "Active": ""}.svg`}
                                    alt="userFeed"
                                />
                            </NavLink>:
                            <img 
                                src={process.env.PUBLIC_URL + `/images/icon/userBookmark${activeBookmark === true ? "Active": ""}.svg`}
                                alt="userFeed"
                            />
                            }
                        </li>
                    </ul>
            </div>
    )
}

export default Navigation;