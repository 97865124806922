// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#hashtagList .hashtag {
  color: #1578d7;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/postList/postListTextHelper.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR","sourcesContent":["#hashtagList{\n    .hashtag{\n        color: #1578d7\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
