import React from 'react';
import HeaderOther from '../helper/HeaderStyle/headerOther';
import Loading from '../helper/loading';
import './resetPinOtp.scss'

const ResetPinOtp = (props) => {    
    return (
        <div id='resetPtpPage'>
            <HeaderOther 
                headerText="ยืนยันรหัส OTP"
                prevUrl={`/login`}                
            />
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <img 
                    className='mt-5'
                    src={process.env.PUBLIC_URL+'/images/login/otpLogo.png'}
                    alt="otpLogo"
                />            
                <p className='text-center text-white mt-3 mb-5'>ขอ OTP เพื่อใช้สำหรับ Reset PIN</p>
            </div>
            {
                props.loading ? 
                <Loading />
                    :
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <button className='otpRequest' onClick={props.enterForGetOTP}>ยืนยันขอ OTP</button>
                </div>
            }
        </div>
    )
}

export default ResetPinOtp