// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bottomBar {
  z-index: 10;
  position: fixed;
  bottom: 0px;
  height: 65px;
  width: 100%;
  margin: 0;
  padding-top: 5px;
  background-color: #fff;
  border-top: 1px solid #5858582c;
}
#bottomBar .textNotActive {
  margin: 0;
  color: #BFBFBF;
}
#bottomBar .textActive {
  margin: 0;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/helper/botBar.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,gBAAA;EACA,sBAAA;EACA,+BAAA;AACJ;AAAI;EACI,SAAA;EACA,cAAA;AAER;AAAI;EACI,SAAA;EACA,WAAA;AAER","sourcesContent":["#bottomBar {\n    z-index: 10;\n    position: fixed;\n    bottom: 0px;\n    height: 65px;\n    width: 100%;\n    margin: 0;\n    padding-top: 5px;\n    background-color: #fff;\n    border-top: 1px solid #5858582c;\n    .textNotActive {\n        margin: 0;\n        color: #BFBFBF;\n    }\n    .textActive {\n        margin: 0;\n        color: #000;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
