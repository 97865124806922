// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#profileContainer {
  overflow: auto;
}
#profileContainer .user-edit {
  color: #1567B4;
  background-color: #fff;
  border: 1px solid #1567B4;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 16px;
  font-weight: 600;
}
#profileContainer .editText {
  font-size: 12px;
  font-weight: 400;
  color: #BFBFBF;
}
#profileContainer .postContainer {
  padding-bottom: 64px;
}
#profileContainer .profile-header.profile-info .user-detail {
  bottom: 1.25rem;
}
#profileContainer .profileImg img {
  border-radius: 50%;
}
#profileContainer .followBtn {
  width: 80px;
  height: auto;
  padding: 2px 15px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  transition: ease-in-out 0.1s;
}
#profileContainer .unfollow {
  background-color: #1567B4;
  border: 1px solid #1567B4;
  color: #fff;
}
#profileContainer .follow {
  background-color: #fff;
  border: 1px solid #1567B4;
  color: #1567B4;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/app/userProfile/user-profile.scss"],"names":[],"mappings":"AACA;EACI,cAAA;AAAJ;AACI;EACI,cAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AACR;AACI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AACR;AACI;EACI,oBAAA;AACR;AAEY;EACI,eAAA;AAAhB;AAIQ;EACI,kBAAA;AAFZ;AAKI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,4BAAA;AAHR;AAMI;EACI,yBAAA;EACA,yBAAA;EACA,WAAA;AAJR;AAMI;EACI,sBAAA;EACA,yBAAA;EACA,cAAA;AAJR","sourcesContent":["\n#profileContainer {\n    overflow: auto;\n    .user-edit {\n        color: #1567B4;\n        background-color: #fff;\n        border: 1px solid #1567B4;\n        border-radius: 4px;\n        cursor: pointer;\n        padding: 4px 16px;\n        font-weight: 600;                    \n    }\n    .editText {\n        font-size: 12px;\n        font-weight: 400;\n        color: #BFBFBF;\n    }\n    .postContainer {\n        padding-bottom: 64px;            \n    }\n    .profile-header.profile-info {\n            .user-detail {\n                bottom:1.25rem;\n            }\n    }\n    .profileImg {\n        img {\n            border-radius: 50%;\n        }\n    }\n    .followBtn {\n        width: 80px;\n        height: auto;\n        padding: 2px 15px;\n        border-radius: 5px;\n        font-weight: 600;\n        font-size: 10px;\n        transition: ease-in-out 0.1s;\n    }\n\n    .unfollow {\n        background-color: #1567B4;\n        border: 1px solid #1567B4;\n        color: #fff;\n    }\n    .follow {\n        background-color: #fff;\n        border: 1px solid #1567B4;\n        color: #1567B4;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
