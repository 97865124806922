// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#searchUser {
  background-color: #fff;
  height: 85vh;
  padding-top: 10px;
}
#searchUser .userRecom img {
  border-radius: 50%;
}
#searchUser .userRecom h3 {
  font-size: 16px;
  font-weight: 400;
  color: #0F0F0F;
}
#searchUser .userRecom p {
  font-size: 12px;
  font-weight: 300;
  color: #808080;
}
#searchUser .followBtn {
  width: 80px;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding: 2px 15px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  transition: ease-in-out 0.1s;
}
#searchUser .unfollow {
  background-color: #1567B4;
  border: 1px solid #1567B4;
  color: #fff;
}
#searchUser .follow {
  background-color: #fff;
  border: 1px solid #1567B4;
  color: #1567B4;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/search/searchPageUser.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;EACA,iBAAA;AACJ;AAEQ;EACI,kBAAA;AAAZ;AAEQ;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AAAZ;AAEQ;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AAAZ;AAII;EACI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,4BAAA;AAFR;AAKI;EACI,yBAAA;EACA,yBAAA;EACA,WAAA;AAHR;AAKI;EACI,sBAAA;EACA,yBAAA;EACA,cAAA;AAHR","sourcesContent":["#searchUser {\n    background-color: #fff;\n    height: 85vh;\n    padding-top: 10px;\n\n    .userRecom {\n        img {\n            border-radius: 50%;\n        }\n        h3 {\n            font-size: 16px;\n            font-weight: 400;\n            color: #0F0F0F;\n        }\n        p {\n            font-size: 12px;\n            font-weight: 300;\n            color: #808080;\n        }\n    }\n\n    .followBtn {\n        width: 80px;\n        height: auto;\n        margin-top: auto;\n        margin-bottom: auto;\n        padding: 2px 15px;\n        border-radius: 5px;\n        font-weight: 600;\n        font-size: 10px;\n        transition: ease-in-out 0.1s;\n    }\n\n    .unfollow {\n        background-color: #1567B4;\n        border: 1px solid #1567B4;\n        color: #fff;\n    }\n    .follow {\n        background-color: #fff;\n        border: 1px solid #1567B4;\n        color: #1567B4;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
