import React from 'react'
import { Modal } from 'react-bootstrap';
import './userModalStyle.scss'

const Usergender = (props) => {
    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal} centered className="customModalNoInput">
            <img className="close" onClick={props.handleCloseModal} src={process.env.PUBLIC_URL +  `/images/icon/closeBtn.svg`}/>
            <Modal.Header className="custom-modal-header">
                <Modal.Title>เลือกเพศ</Modal.Title>
            </Modal.Header>
            <Modal.Body className="chooseGender custom-modal-body">
                <p>ชาย</p>
                <p>หญิง</p>
                <p>ไม่ระบุ</p>
            </Modal.Body>
        </Modal>
    )
}

export default Usergender