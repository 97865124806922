// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#userProfileInfo {
  width: 80%;
}
#userProfileInfo p {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #000;
}
#userProfileInfo .imgContainer {
  background-color: #F4F4F8;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
#userProfileInfo .imgContainer img {
  margin: 0 auto;
  width: 20px;
  height: 20px;
}
#userProfileInfo img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
#userProfileInfo .infoContainer {
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
}
#userProfileInfo .infoContainer .infoDetail {
  padding: 15px 0px 15px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 1px 0px;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/app/userProfile/userProfileInfo.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;AAAI;EACI,SAAA;EACA,UAAA;EACA,6BAAA;AAER;AAAI;EACI,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAER;AADQ;EACI,cAAA;EACA,WAAA;EACA,YAAA;AAGZ;AAAI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAER;AAAI;EACI,eAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,iDAAA;AAER;AADQ;EACI,2BAAA;EACA,iDAAA;AAGZ","sourcesContent":["#userProfileInfo {\n    width: 80%;\n    p{\n        margin: 0;\n        padding: 0;\n        border-bottom: 1px solid #000;\n    }\n    .imgContainer {\n        background-color: #F4F4F8;\n        margin-right: 20px;\n        width: 30px;\n        height: 30px;           \n        border-radius: 4px;\n        img {\n            margin: 0 auto;\n            width: 20px;\n            height: 20px;            \n        }\n    }\n    img {\n        width: 20px;\n        height: 20px;\n        margin-right: 20px;\n    }\n    .infoContainer {    \n        cursor: pointer;\n        width: 100%;\n        overflow: hidden;\n        border-radius: 10px;        \n        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;\n        .infoDetail {\n            padding: 15px 0px 15px 20px;\n            box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 1px 0px;       \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
