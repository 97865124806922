import axios from "axios";


const REST_API_URL = "https://silverman-login-qsobffrqcq-as.a.run.app";
const credentials = btoa('MSVWTHDZCS1lxgruD1yTteOeXHPTQYlrODkEiC09VLpMVahfBiADM6eXo:05c5593b8811deda');

class OTP {
    async getOTP(data) {  
        const url = `${REST_API_URL}/auth/otp`;
        return axios.post(url, data,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${credentials}`
            },            
        });
    }
    async verifyOTP(data) {  
        const url = `${REST_API_URL}/auth/verify`;
        return axios.post(url, data,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${credentials}`
            }
        });
    }
}

class PIN {
    async createPIN(data) {  
        const url = `${REST_API_URL}/auth/create_pincode`;        
        return axios.post(url, data,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem("refreshToken")}`
            }
        });
    }
    async authPIN(data) {  
        const url = `${REST_API_URL}/auth/auth_pincode`;
        return axios.post(url, data,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem("refreshToken")}`
            }
        });
    }
    // async checkProcessLogin(userName){        
    //     const url = `${REST_API_URL}/auth/auth_status?username=${userName}`;
    //     return axios.get(url,{
    //         headers: {
    //             Authorization: `Bearer ${credentials}`
    //         }
    //     });
    // }
}

class PDPA {
    async acceptPdpa(data) {          
        const url = `${REST_API_URL}/auth/pdpa`;   
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem("authToken")}`
            }
        });
    }    
}

class ProcessLogin {
    async checkProcessLogin(data) {  
        const url = `${REST_API_URL}/auth/auth_status?username=${data}`;        
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${credentials}`
            }
        });
    }
}

const loginApi = {
    OTP, PIN, PDPA, ProcessLogin
};

export default loginApi;