import React, {useEffect, useState} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import './headerSearch.scss';
import ModalSelectCategories from "../modalSelectCategories";


const HeaderSearch = (props) => {
    const searchType = props.searchType;
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [categoriesSelected, setCategoriesSelected] = useState("nofilter");
    
    const prevUrl = props.prevUrl;

    const addCategories = (status) => {
        setShowModal(status)
    }

    const selectCategories = (allCate) => {
        setCategoriesSelected(...allCate)
    }

    useEffect(() => {
        props.handleSelectedCategory && props.handleSelectedCategory(categoriesSelected || "nofilter")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[categoriesSelected])

    const backPage = ()=> {
        navigate(-1)
    }
    return (
        <>
            <div className="iq-top-navbar bg-dark d-flex flex-row justify-content-between align-items-center pb-4 pt-4">
                {prevUrl ?
                <NavLink to={prevUrl}>
                    <img 
                        className="leftArrow ms-4 me-2"
                        src={process.env.PUBLIC_URL + `/images/icon/leftArrow.svg`} alt="leftArrow"
                    />
                </NavLink>:
                    <img 
                        className="leftArrow ms-4 me-2"
                        src={process.env.PUBLIC_URL + `/images/icon/leftArrow.svg`} alt="leftArrow"
                        onClick={backPage}
                    />            
                }
                <input 
                    className="searchBar showSearchBar submit"
                    onKeyPress={props.onKeyPress} 
                    type="search" 
                    placeholder="ค้นหา"
                    value={props.searchText} 
                    onChange={props.handleInput}
                />    
                {
                    searchType === 'popular' && 
                    <img 
                        onClick={()=>addCategories(true)}
                        className="me-4 systemFilteIcon"
                        src={process.env.PUBLIC_URL + `/images/icon/systemFilteIcon.svg`} alt="systemFilteIcon"
                    />    
                }            
            </div>
            <ModalSelectCategories 
                showModal={showModal}
                headerTextCate='เลือกหมวดหมู่'
                btnName='แสดง'
                numberOfFiler={1}
                addCategories={addCategories}
                selectCategories={selectCategories}
            />
        </>
    );
};

export default HeaderSearch;
