import React from "react";
import {useNavigate, NavLink } from "react-router-dom";
import './headerFeed.scss';
import { useUserName } from "../../userNameContext";

const HeaderFeed = (props) => {
  const {userId} = useUserName();
  const myUserID =  userId;
  const userImage = props.userProfileImage
  const navigate = useNavigate()

  const goToFeed = ()=> {
    navigate('/feed/all')
  }

  return (
      <div id="headerFeed">
        <div className="iq-top-navbar bg-dark d-flex flex-row justify-content-between align-items-center pb-4 pt-4">   
          <p className="headerName d-flex text-light m-0 ms-4" onClick={goToFeed}>
            Silverman Society Club <span className="ms-3 showHeaderDescription">ชมรมเพื่อลูกบ้าน แลกเปลี่ยนไอเดียในการพัฒนานิติบุคคล</span>            
          </p>           
          <div className={`d-flex flex-row justify-content-between`}>  
                <NavLink to='/feed/search'>                
                <img
                  src={process.env.PUBLIC_URL + `/images/icon/saerch.svg`}
                  className="searchIcon me-4"
                  alt="user"
                  loading="lazy"
                />
                </NavLink>
                <NavLink to={`/dashboard/app/profilelist/${myUserID}`}>
                  {
                    (userImage ?
                      userImage?.startsWith("profile") ?                                                      
                      <img className="rounded-circle me-4" width="30px" height="30px"  src={process.env.PUBLIC_URL + `/images/profile/${userImage}.png`} alt="userImage"/>
                        :
                      <img className="rounded-circle me-4" width="30px" height="30px"  src={userImage} alt="userImage"/>
                    : null
                    )
                  }

                </NavLink>
          </div>  
        </div>
      </div>
  );
};

export default HeaderFeed;
