import React, {useState, useEffect}  from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import HeaderFeed from '../helper/HeaderStyle/headerFeed'
import FeedBroadPost from './feedBroadPost'
import BotBar from '../helper/botBar'
import InfiniteScroll from "react-infinite-scroll-component";
import './feedBroad.scss'
import socialApi from '../../../service/services'
import { motion } from 'framer-motion';
import Navigation from '../helper/navigation'
import CreatePostBtn from '../postList/createPostBtn'
import Loading from '../helper/loading'
import allCategories from '../helper/allCategoriesList'
import { useUserName } from '../userNameContext'
import PinConfirm from '../login/pinConfirm'

const FeedBroad = () => {
    const [authorization, setAuthorization] = useState(true)
    const { userId } = useUserName();
    const userService = new socialApi.PostList();
    const userServiceUser = new socialApi.User();
    const param = useParams().categories
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    // for infinite scroll
    const [hasMore, setHasMore] = useState(true);
    const [index, setIndex] = useState(2);
    const itemPerFetch = 10 // หลังบ้านส่งมาทีละ 10
    
    const [userProfileName, setUserProfileName] = useState("")
    const [userProfileImage, setUserProfileImage] = useState("")
    
    const findCategory = allCategories.find(category => category.engName === param)?.thName;  

    useEffect(() => {         
         fetchData()     
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param]);

    const fetchData = async () => {
        if (!findCategory) {
            navigate('/feed/all');
            return null; 
        }
        
        setLoading(true)
        setHasMore(true);
        setAuthorization(true)
        setIndex(2)
        try {
            const resUserData = await userServiceUser.getSearchUserData(userId);
            const res = await userService.getPost(findCategory, 1, userId)
            
            setUserProfileImage(resUserData.data.user.userImage)
            setUserProfileName(resUserData.data.user.userName)

            setData(res.data.postList)
            setLoading(false);
            res.data.postList?.length < itemPerFetch ? setHasMore(false) : setHasMore(true)      

         } catch (error) {
            if(error.response.status === 401){
                setAuthorization(false)
            } else {
                console.log(error)
            }   
            setLoading(false);
         }
    };


    const fetchMoreData = async () => {
        setAuthorization(true)
        try {
            const res = await userService.getPost(findCategory, index, userId)
            if (res.data.postList && res.data.postList.length > 0) {
                setData((prevItems) => [...prevItems, ...res.data.postList]);  
                setHasMore(true);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        } catch (error) {
            if(error.response.status === 401){
                setAuthorization(false)
            } else {
                console.log(error)
            }  
        }
        setIndex((prevIndex) => prevIndex + 1);
    };

    return (
        authorization ?
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
            <HeaderFeed userProfileImage={userProfileImage}/>
            <Navigation />
            {loading?
                <Loading />
                :
                <>
                    <InfiniteScroll
                        dataLength={data?.length || []}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<Loading />}
                    >                
                        <Col id="feedBroad" className="m-0 p-0 mb-2">                    
                            <FeedBroadPost                 
                                feedPost={data} 
                                loading={loading}  
                                allCategories={allCategories}
                                userProfileName={userProfileName}
                                userProfileImage={userProfileImage}
                            />
                        </Col>
                    </InfiniteScroll>
                </>
                }
            <CreatePostBtn />   
            <BotBar />
        </motion.div> 
            :
        <PinConfirm />
    )
}

export default FeedBroad