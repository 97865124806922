import React, {useEffect}  from 'react'
import { NavLink } from 'react-router-dom'
import './editModal.scss'
import ReactModal from 'react-modal';


const EditModal = (props)=> {
            const isEditPost = props.editPost || false;
            const postId = props.postId || "";

            useEffect(() => {
                ReactModal.setAppElement('#editModal');
            }, []);

            return (<div id="editModal">            
                {/* Modal */}
                <ReactModal 
                    isOpen={props.showModal}
                    contentLabel="Modal #1 Global Style Override Example"
                    onRequestClose={props.onColoseModal}
                    style={props.customModalStyles}            
                >
                    <div className='d-flex flex-column align-items-center modalEditComment'>
                        {
                            isEditPost ?
                            <p className="btnAction">
                                <NavLink to={`/edit/post/${postId}`}>                                
                                    <img 
                                        className='me-3'
                                        src={process.env.PUBLIC_URL + `/images/icon/penEdit.svg`}
                                        alt="penEdit"
                                    /> แก้ไข                                
                                </NavLink>
                            </p>
                            :
                            <p className="btnAction" onClick={props.onEditComment}>
                                <img 
                                    className='me-3'
                                    src={process.env.PUBLIC_URL + `/images/icon/penEdit.svg`}
                                    alt="penEdit"
                                /> แก้ไข
                            </p>
                        }
                        <p className="btnAction" onClick={props.onDelete}>
                            <img 
                                className='me-3'
                                src={process.env.PUBLIC_URL + `/images/icon/recycleBin.svg`}
                                alt="recycleBin"
                            /> ลบ
                        </p>
                    </div>
                </ReactModal>
            </div>)

}

export default EditModal