import React, { useState, useEffect }  from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion'
import './createPost.scss';
import HeaderOther from '../helper/HeaderStyle/headerOther';
import ModalSelectCategories from '../helper/modalSelectCategories';
import socialApi from '../../../service/services';
import Resizer from "react-image-file-resizer";
import { useUserName } from '../userNameContext';
import PinConfirm from '../login/pinConfirm';


const CreatePost = ()=> {
    const { userId } = useUserName();
    const userService = new socialApi.PostList();
    const myUserID = userId;
    
    const navigate = useNavigate();
    const param = useParams().postId;
    
    const headerText = param ? 'แก้ไขโพสต์':'สร้างโพสต์';

    const [text, setText] = useState('');
    const [textCount, setTextCount] = useState(0);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [oldImages, setOldImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [authorization, setAuthorization] = useState(true)
    const [onProcess, setOnProcess] = useState(false)
    
    // when edit
    const getOldPost = async (postId) => {
        setAuthorization(true)
        try {
            const response = await userService.getOldPost(postId, myUserID);
            if (response.data.post.userId !== myUserID) {
                navigate('/feed/all');
            } else {
                setText(response.data.post.postText);
                setTextCount(response.data.post.postText.length);
                setCategoriesSelected(response.data.post.categories);
                setSelectedImages(response.data.post.postImage);
            }
        } catch (error) {
            if(error.response.status === 401){
                setAuthorization(false)
             } else {
                console.log(error)
             }   
        }
    };

    // when edit
    useEffect(() => {
        if (param !== undefined) {
            getOldPost(param);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param]);

    useEffect(() => {
        if(!onProcess) {
            const handleKeyPress = (e) => {
                if (e.key === 'Enter') {
                    e.preventDefault(); 
                    if (categoriesSelected.length > 0 && textCount > 0) {
                        param ? updatePost() : createPost();
                    }
                }
            }; 
            document.addEventListener('keydown', handleKeyPress);
        
            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
          }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onProcess, categoriesSelected, textCount, param]);

    const checkHashTag = (textpost) => {
        const text = textpost;
        const foundKeywords = [];
        
        // Extract hashtags from the text
        const hashtags = text.match(/#(\S+)/g);
        
        if (hashtags) {
          hashtags.forEach(hashtag => {
            const keywords = hashtag.replace('#', '').split('#');
            foundKeywords.push(...keywords);
          });
        }
        
        return foundKeywords
    }

    const updatePost = async () => {
        setAuthorization(true)
        const hashtags = checkHashTag(text) || []                  
        for (const img of oldImages) {
            if (typeof img === 'string') {   
                const pathOldImage = img.substring(img.lastIndexOf('/') + 1);
                await userService.deleteImage(pathOldImage, "postImage");
            }
        }

        let editImage = [];

        for (const uploadImage of selectedImages) {
            if (typeof uploadImage === 'string') {            
                editImage.push(uploadImage);
            } else {                
                const newUploadedImage = await uploadFile([uploadImage]);
                editImage.push(newUploadedImage[0]);
            }
        }    
        const data = {
            "postId": param,
            "userId": myUserID,
            "categories": categoriesSelected,
            "postText": text,
            "postImage": editImage,
            "hashtags": hashtags
        }
        try {
            userService.updatePost(data)
            .then(response => {
                if (response.status === 200) {            
                    navigate('/feed/all');
                } else {
                    throw new Error(`Error with status: ${response.status}`);
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    setAuthorization(false)
                } else {
                    console.log(error)
                }   
            });
        } catch (error) {
          console.error('Error updating data:', error);
        }
    };
    
    const createPost = async () => {          
        setAuthorization(true)
        setOnProcess(true)
        try {
            const uploadedImages = await uploadFile(selectedImages);
            const hashtags = checkHashTag(text) || [];
            const data = {
                "userId": myUserID,
                "categories": categoriesSelected,
                "postText": text,
                "postImage": uploadedImages, 
                "hashtags": hashtags
            };
    
            await userService.createPost(data)
                .then(response => {
                    if (response.status === 200) {            
                        navigate(-1);
                    } else {
                        throw new Error(`Error with status: ${response.status}`);
                    }
                })
                .catch(error => {
                    if(error.response.status === 401){
                        setAuthorization(false)
                    } else {
                        console.log(error)
                    }   
                });
    
        } catch (error) {
            console.error('Error create post:', error);
        } finally {
            setOnProcess(false)
        }
    };
   
    const handleChange = (event) => {
        const newText = event.target.value;
        setText(newText);
        setTextCount(newText.length);
    };

    // Categories
    const addCategories = (status) => {
        setShowModal(status)
    }
    
    const selectCategories = (allCate) => {
        setCategoriesSelected(allCate)
    }

    // IMAGE
    const resizeImg = (file) => {
        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                450,
                450,
                'JPEG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file',
                450, 
                450,
                false,
                () => {
                    reject(new Error('Image resizing failed.'));
                }
            );
        });
    };

    const uploadFile = async (files) => {
        setAuthorization(true)
        try {   
            const uploadPromises = files.map(async (img) => {
                try {
                    const resize = await resizeImg(img)
                    const response = await userService.uploadImage(resize, "postImage");
                    let res = response.data.imageUpload;
                    return res;
                } catch (error) {
                    if(error.response.status === 401){
                        setAuthorization(false)
                    } else {
                        console.log(error)
                    }   
                    throw error; 
                }
            });
            const imageUrlArray = await Promise.all(uploadPromises);
            return imageUrlArray;
        } catch (error) {
            console.error('Error uploading files:', error);
            throw error;
        }
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            setSelectedImages((prevImages) => [...prevImages, ...files]);
        }
    };

    const delImage = (index) => {
        const updatedImages = [...selectedImages];
        const removedItem = updatedImages.splice(index, 1)[0];
        setSelectedImages(updatedImages);
        setOldImages((prev)=>[...prev, removedItem])
    }

    return (
        authorization ?
        <motion.div
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
        >
                    <div id="postContainer" className="bg-white ">           
                        <HeaderOther 
                            headerText={headerText}         
                        />            
                        <div className="createPost">
                            <div className='postText mt-4 d-flex flex-column align-items-center'>
                                <textarea                   
                                    value={text}
                                    maxLength={500}
                                    onChange={handleChange}
                                    placeholder='เขียนข้อความเพื่อสนทนากับเพื่อนในโครงการ เพิ่มหมวดหมู่ กับ Hastag เพื่อให้พบง่ายขึ้น'
                                />
                                <p className='textCount'>({textCount}/500)</p>
                            </div>
                            <p className='subject mt-2'>เลือกหมวดหมู่<span className='redStar'>*</span></p>
                            <div className='d-flex flex-row flex-wrap gap-3'>
                                {                    
                                    categoriesSelected.map((cate, index)=>{
                                        return <div key={index} 
                                        className='cateSeleted d-flex justify-content-center align-items-center'>
                                            {cate}
                                        </div>
                                    })                    
                                }
                                <img
                                    className='addCategory'
                                    alt="addCategory"                                    
                                    onClick={()=>addCategories(true)} 
                                    src={process.env.PUBLIC_URL + `/images/icon/addCategory.svg`}
                                />
                            </div>
                            <div className='d-flex flex-row justify-content-between mt-4'>
                                <p className='subject'>รูปภาพ</p>
                                <p className='description'>แนบรูปสูงสุดได้ไม่เกิน 10 รูป</p>
                            </div>
                            <div className='w-100'>
                                <div className='row m-0 w-100'>
                                {selectedImages.length > 0 && (
                                    selectedImages.map((image, index) => {
                                    return(
                                        
                                        <div key={index} className='col-4 imageUploaded mb-2'>
                                            <img
                                                className='image'
                                                key={index}
                                                src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                                                alt={`Preview ${index + 1}`}                               
                                            />
                                            <img 
                                                onClick={() => delImage(index)}
                                                className='delImage'                                
                                                src={process.env.PUBLIC_URL + `/images/icon/delImage.svg`}
                                                width='20px'
                                                height='20px'
                                                alt="delImage"     
                                            />
                                        </div>
                                    )})
                                    )}
                                    <label htmlFor="fileInput" className='imageUploaded'>
                                        <div className='uploadIconContainer d-flex justify-content-center align-items-center'>
                                            <img 
                                                className='cameraIcon'                                
                                                src={process.env.PUBLIC_URL + `/images/icon/camera.svg`}
                                                alt="cameraIcon"
                                            />
                                        </div>
                                        <img 
                                            className='addImage'                                
                                            src={process.env.PUBLIC_URL + `/images/icon/addImageIcon.svg`}
                                            width='20px'
                                            height='20px'
                                            alt="addImage"
                                        />
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="fileInput"
                                        onChange={handleImageChange}
                                        multiple
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            </div>
                        </div>            
                        
                        <div className='postBtnContainer d-flex justify-content-center align-items-center'>
                            <button                            
                            onClick={param? updatePost : createPost} 
                            disabled={
                                categoriesSelected.length <= 0 ||
                                textCount <= 0 ||
                                onProcess
                            }
                            className={`postBtn postBtn${(categoriesSelected.length > 0 && textCount > 0)? "Enable" : "Disable"}`}>
                                โพสต์
                            </button>
                        </div>
                        
                        <ModalSelectCategories
                            showModal={showModal}
                            headerTextCate='เลือกได้สูงสุด 3 หมวดหมู่'
                            btnName='บันทึก'
                            numberOfFiler={3}
                            addCategories={addCategories}
                            selectCategories={selectCategories}

                        />
                    </div>   
        </motion.div>
        :
        <PinConfirm />
    )
}

export default CreatePost