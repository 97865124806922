// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#createProfile p {
  color: #808080;
  font-size: 12px;
}
#createProfile .userProfileImg {
  position: relative;
}
#createProfile .userProfileImg .profileImg {
  transition: ease-in-out 0.7s;
}
#createProfile .userProfileImg .uploadIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
#createProfile .creatUserName {
  width: 75%;
}
#createProfile .creatUserName input {
  width: 90%;
  font-size: 18px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #E2E2E2;
}
#createProfile .creatUserName input::placeHolder {
  color: #E2E2E2;
}
#createProfile .creatUserName .clearInput {
  cursor: pointer;
}
#createProfile .ruleOfCreateProfile {
  font-size: 12px;
  color: #808080;
  margin-bottom: 0;
}
#createProfile .createProfile {
  color: #FFFFFF;
  min-width: 326px;
  min-height: 48px;
  border-radius: 4px;
  padding: 12px 24px;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}
#createProfile .createProfileBtnDisable {
  background-color: #BFBFBF;
  border: 1px solid #BFBFBF;
}
#createProfile .createProfileBtnEnable {
  background-color: #1567B4;
  border: 1px solid #1567B4;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/loginPage/createProfile.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,eAAA;AAAR;AAEI;EACI,kBAAA;AAAR;AACQ;EACI,4BAAA;AACZ;AACQ;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,eAAA;AACZ;AAEI;EACI,UAAA;AAAR;AACQ;EACI,UAAA;EACA,eAAA;EACA,YAAA;EACA,6BAAA;EACA,gCAAA;AACZ;AACQ;EACI,cAAA;AACZ;AACQ;EACI,eAAA;AACZ;AAGI;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AADR;AAII;EACI,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,4BAAA;EACA,eAAA;AAFR;AAII;EACI,yBAAA;EACA,yBAAA;AAFR;AAII;EACI,yBAAA;EACA,yBAAA;AAFR","sourcesContent":["#createProfile {\n    p{\n        color: #808080;\n        font-size: 12px;\n    }\n    .userProfileImg {\n        position: relative;\n        .profileImg {\n            transition: ease-in-out 0.7s;\n        }\n        .uploadIcon {\n            position: absolute;\n            bottom: 0;\n            right: 0;\n            cursor: pointer;\n        }\n    }\n    .creatUserName {\n        width: 75%; \n        input {\n            width: 90%; \n            font-size: 18px;\n            border: none;\n            background-color: transparent;\n            border-bottom: 1px solid #E2E2E2;\n        }       \n        input::placeHolder {\n            color: #E2E2E2;\n        }\n        .clearInput {\n            cursor: pointer;\n        }\n    }\n\n    .ruleOfCreateProfile {\n        font-size: 12px;\n        color: #808080;\n        margin-bottom: 0;\n    }\n    \n    .createProfile {\n        color: #FFFFFF;\n        min-width: 326px;\n        min-height: 48px;\n        border-radius: 4px;\n        padding: 12px 24px;\n        transition: ease-in-out 0.2s;\n        cursor: pointer;\n    }\n    .createProfileBtnDisable {\n        background-color: #BFBFBF;\n        border: 1px solid #BFBFBF;\n    }\n    .createProfileBtnEnable {\n        background-color: #1567B4;\n        border: 1px solid #1567B4;\n        \n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
