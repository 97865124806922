import React from 'react'
import { Modal } from 'react-bootstrap';
import './userModalStyle.scss'

const UserPass = (props) => {
    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal} centered className="customModalWithInput">            
            <img className="close" onClick={props.handleCloseModal} src={process.env.PUBLIC_URL +  `/images/icon/closeBtn.svg`}/>
            <div className='inputContainer d-flex flex-column align-items-center'>
                <p className='text-center mb-5'>เปลี่ยนรหัสผ่าน</p>
                <p className='mt-4 mb-2'>ใส่รหัสผ่านใหม่ที่ต้องการ</p>
                <input 
                    placeholder='รหัสผ่าน'
                />
                <button className='btnConfirm mt-5'>ยืนยัน</button>
            </div>
        </Modal>
    )
}

export default UserPass