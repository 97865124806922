import React, {useEffect, useState} from 'react'
import { NavLink } from "react-router-dom";
import {Col} from 'react-bootstrap'
import Card from '../helper/Card';
import ShareOffcanvas from '../helper/shareOffcanvas';
import './postList.scss';
import Lightbox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";
import EditModal from '../helper/editModal';
import Swal from 'sweetalert2';
import HashtagText from './postListTextHelper';
import socialApi from '../../../service/services';
import allCategories from '../helper/allCategoriesList';
import { useUserName } from '../userNameContext';

const PostList = (props) => {
    const { userId } = useUserName();
    const findCategory = (cate) =>{
      let cateEng = allCategories.find(category => category.thName === cate)?.engName;
      return cateEng
    }

    const myUserID = userId;
    const userServiceLike = new socialApi.Like()
    const userServicePost = new socialApi.PostList()
    const userServiceBookmark = new socialApi.BookmarkList()

    const [feedPost, setFeedPost] = useState(props.feedPost)
    const [postId, setPostId] = useState("");
    const [showModal, setShowModal] = useState(false);
    
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageForDelete, setImageForDelete] = useState("")
    
    useEffect(() => {
      setFeedPost(props.feedPost);
    }, [props.feedPost]);

    const onBookMark = async (postId) => {      
      let tempFeedPost = feedPost.map(item => (        
        item.postId === postId ? {
          ...item,
          alreadyBookmark: !item.alreadyBookmark,
        } : item
      ));
      setFeedPost(tempFeedPost)

        try {
            const data = {
                "postId": postId,
                "userId": myUserID
            }
            await userServiceBookmark.addBookmarkList(data)
          } catch (error) {
            if(error.response.status === 401){
                window.location.reload();
            } else {
                console.log(error)
            }   
          }
    
    }    

    const onLike = async (postId) => {  
      let tempFeedPost = feedPost.map(item => (        
        item.postId === postId ? {
          ...item,
          alreadyLike: !item.alreadyLike,
          likesCount: item.alreadyLike ? item.likesCount - 1 : item.likesCount + 1
        } : item
      ));
      setFeedPost(tempFeedPost)
        try {
            const data = {
                "postId": postId,
                "userId": myUserID
            }
            await userServiceLike.getlike(data)
          } catch (error) {
            if(error.response.status === 401){
                window.location.reload();
            } else {
                console.log(error)
            }   
          }
    
    };
    
    const openLightbox = (image) => {
      setSelectedImage(image);
      setLightboxOpen(true);
    };
    
    const showEditModal = (postId, image) => {
        setShowModal(true)
        setPostId(postId)
        setImageForDelete(image)
    }

    const onColoseModal = () => {
      setShowModal(false)
    }

    const onDeletePost = async () => {
      onColoseModal()
      try {
        const result = await Swal.fire({
          icon: 'question',
          iconColor: '#1567B4',
          imageWidth: 100,
          html: `
            <label className='swal2-title-custom text-center'>คุณแน่ใจใช่ไหมที่จะลบ</label>
          `,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'ลบ',
          cancelButtonText: 'ยกเลิก',
          reverseButtons: true,
          confirmButtonColor: '#1567B4',
        });
    
        if (result.isConfirmed) {
          await deletePost(postId, imageForDelete);
          Swal.fire({
            icon: 'success',
            title: 'ลบสำเร็จ',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const deletePost = async (postId, image) => {      
        try {
          const data = {
            "postId":postId,
            "userId":myUserID
          }
          for (const img of image) {
            const pathOldImage =  img.substring(img.lastIndexOf('/') + 1)
            userServicePost.deleteImage(pathOldImage).then((response)=>{
              if(response.status === 200){
                window.location.reload();
              }
            })
          }
          
          userServicePost.deletePost(data).then((response)=>{
            if(response.status === 200){
              window.location.reload();
            }
          })

        } catch (error) {
          console.error(error);
        }
    };

    const customModalStyles = {
      content: {
         display: 'flex',
         flexDirection: 'column',
         minWidth: '300px',
         maxWidth: '80%',
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         padding: '0px',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         borderRadius: '8px',
         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',            
      },
      overlay: {
         zIndex: 2000,
         backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
  
    }

    return <>
        {feedPost?.map((feed, index)=>{
            const isLiked = feed.alreadyLike
            const isBookmarked = feed.alreadyBookmark;
            const canEditPost = feed.userId === myUserID      
            const imageNumberThatNotBackDrop = 2

            return (            
                    <Col key={index}>
                        <Card className="card-block card-stretch card-height">
                            <Card.Body>
                                <div id="postList">
                                    <div className="user-post-data">
                                        <div className="d-flex justify-content-between">
                                            <div className="profileImg me-3">
                                                <NavLink to={`/dashboard/app/profilelist/${feed.userId}/`}>
                                                  {
                                                    feed.userImage.startsWith("profile") ?
                                                    <img className="" width="70px" height="70px" src={process.env.PUBLIC_URL + `/images/profile/${feed.userImage}.png`} alt="userImage"/>
                                                    :                                                      
                                                    <img className="" width="70px" height="70px" src={feed.userImage} alt="userImage"/>
                                                  }
                                                </NavLink>
                                            </div>
                                            <div className="w-100">
                                                <div className="d-flex flex-column justify-content-between">                                                        
                                                    <div className="d-flex flex-row justify-content-between card-post-toolbar">
                                                      <NavLink to={`/dashboard/app/profilelist/${feed.userId}/`}>
                                                        <h5 className="mb-0 d-inline-block mb-2">{feed.userName}</h5>
                                                      </NavLink>
                                                        <div>
                                                          <span className="mb-0 text-secondary">{feed.timestamp}</span>
                                                          {canEditPost && 
                                                          <img   
                                                              className='editDotIcon ms-3 p-2'                                              
                                                              onClick={()=>showEditModal(feed.postId, feed.postImage)}
                                                              src={process.env.PUBLIC_URL + `/images/icon/dots.svg`}
                                                              alt="editDotIcon"
                                                          />
                                                          }
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                      <div id="feedCard" className='d-flex flex-wrap gap-1'>
                                                        {
                                                          feed.categories?.map((cate,index)=>(
                                                            <NavLink key={index} to={`/feed/${findCategory(cate)}`}>                      
                                                              <p  className='feedCategory p-1 ps-3 pe-3 text-center'>{cate}</p>
                                                            </NavLink>
                                                          ))
                                                        }
                                                      </div>               
                                                    </div>                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <HashtagText 
                                          text={feed.postText}
                                          hashtags={feed.hashTags}
                                        />
                                    </div>
                                    {feed.postImage.length > 0 &&
                                    <div className="container mt-3">
                                      <div className="row">                               
                                          {feed.postImage.map((img, index) => {
                                            return (
                                              index < 3 &&
                                              <div
                                              className={`col postImageContainer ${
                                                feed.postImage.length > 2 && index === 2 ? 'moreThanThree' : 'me-2'
                                              }`}
                                              key={index}
                                              >
                                                {index <= 1 && (
                                                  <img
                                                    src={img}
                                                    alt={`post${index}`}
                                                    className="rounded"
                                                    height="100%"
                                                    width="100%"
                                                    onClick={() => openLightbox(img)}
                                                  />
                                                )} 
                                                {index === 2 && (
                                                  <>
                                                    <img
                                                      src={img}
                                                      alt={`post${index}`}
                                                      className="rounded"
                                                      height="100%"
                                                      width="100%"
                                                      onClick={() => openLightbox(img)}
                                                    />
                                                    {feed.postImage.length > 3 && <div className="postImageContainer blackdrop" onClick={() => openLightbox(feed.postImage)}>
                                                      +{feed.postImage.length - imageNumberThatNotBackDrop}
                                                    </div>}
                                                  </>
                                                )}
                                              {lightboxOpen && (
                                                  Array.isArray(selectedImage) ?
                                                  <Lightbox onClose={() => setLightboxOpen(false)} startIndex={2} images={selectedImage}/>
                                                  :
                                                  <Lightbox onClose={() => setLightboxOpen(false)} image={selectedImage}/>
                                                )}
                                              </div>
                                          )})}                                            
                                      </div>
                                    </div>}

                                    <div className="postActionBtn post-action d-flex flex-row justify-content-between mt-4 me-2 ms-2">                                                                    
                                        <div className='bookmarkBtn d-flex flex-row' onClick={()=> onBookMark(feed.postId)}>                                                                   
                                            <img 
                                              src={process.env.PUBLIC_URL + `/images/icon/bookmark${isBookmarked ? "Active":""}.svg`} 
                                              alt="bookmarkIcon"
                                            />                                                                        
                                        </div>
                                        <div className='d-flex flex-row'>
                                            <div className='me-4 ms-4 d-flex flex-row' onClick={() => onLike(feed.postId)}>
                                                <img className="likeBtn me-2" 
                                                src={process.env.PUBLIC_URL + `/images/icon/like${isLiked ? "Active" : ""}.svg`} 
                                                alt="likeIcon"  
                                              /> 
                                                <span className='likeCount'>{feed.likesCount}</span>
                                            </div>
                                            <div className='me-4 ms-4 d-flex flex-row'>
                                                <NavLink to={`/comment/${feed.postId}`}>
                                                    <img 
                                                      className="commentBtn me-2" 
                                                      src={process.env.PUBLIC_URL + `/images/icon/comment.svg`} 
                                                      alt="commentIcon"   
                                                    /> 
                                                    {feed.commentsCount}
                                                </NavLink>
                                            </div>
                                            <ShareOffcanvas 
                                              className="shareBtn"
                                              url={`/comment/${feed.postId}`}
                                            />
                                        </div>                                                                   
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>                        
                    </Col>                                
            )
        })}
         <EditModal 
            showModal={showModal}
            customModalStyles={customModalStyles}
            onColoseModal={onColoseModal}
            onDelete={onDeletePost}
            editPost={true}
            postId={postId}
        />       
    </>
    
}

export default PostList;