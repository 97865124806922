import React, { useState } from 'react'
import './index.scss'
import CreaetProfile from './loginPage/createProfile'
import { motion } from 'framer-motion'
import Login from './login/login'


const Index = () => {
    const [completeLogin , setCompleteLogin] = useState(false);
    const checkCompleteLogin = () => {  
        // login complete but have no profile
        setCompleteLogin(true)
    }

    return (
        
        <motion.div
            initial={{
                opacity: 0-0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
        >
            <div id="content-page" className="content-page bg-white">
                {
                    completeLogin ?
                        <CreaetProfile />
                        :
                        <Login 
                            checkCompleteLogin={checkCompleteLogin}
                        />
                }               
            </div>
        </motion.div>
    )
}

export default Index
