import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import './searchRecom.scss';
import HeaderSearch from '../helper/HeaderStyle/headerSearch';
import AnimatePresenceDiv from '../helper/animatedContainer';
import socialApi from '../../../service/services';
import { useUserName } from '../userNameContext';
import PinConfirm from '../login/pinConfirm';

const SearchRecommend = () => {
  const { userId } = useUserName();
  const myUserID = userId;
  const userService = new socialApi.Search();
  const [historySearch, setHistorySearch] = useState([]);
  const [authorization, setAuthorization] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    getSearchHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]); 

  
  const delHistory = (index, searchId) => {
    const tempItem = [...historySearch];
    tempItem.splice(index, 1);
    setHistorySearch(tempItem);
    delSearchHistory(myUserID, searchId)
  };

  const delHistoryAll = () => {
    try {
      for (const history of historySearch) {
        delSearchHistory(myUserID, history.searchHistoryId)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setHistorySearch([])
    }
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.value !== "") {      
      let searchText = event.target.value;
      let newSearch = searchText
      if(searchText.includes("#")){
        newSearch = searchText.replace("#", "%23")
      }
      navigate(`/feed/search/popular/${newSearch}`);
      addSearchHistory(myUserID, searchText) 
    }
  };

  const addSearchHistory = async (userId, search)=>{
    setAuthorization(true)
    const data = {
      "userId" : userId,
      "search" : search,
    }
    try {
      await userService.addSearchHistory(data)
    } catch (error) {
      if(error.response.status === 401){
        setAuthorization(false)
      } else {
          console.log(error)
      }   
    } 
  }

  const delSearchHistory = async (userId, searchId)=>{
    setAuthorization(true)
    const data = {
      "userId" : userId,
      "searchId" : searchId,
    }
    try {
      await userService.delSearchHistory(data)
    } catch (error) {
      if(error.response.status === 401){
        setAuthorization(false)
      } else {
          console.log(error)
      }   
    } 
  }

  const getSearchHistory = async () => {
    setAuthorization(true)
    try {
      const res = await userService.getSearchHistory(userId)
      setHistorySearch(res.data.history);                       
    } catch (error) {
      if(error.response.status === 401){
          setAuthorization(false)
      } else {
          console.log(error)
      }   
    }
  }

  return (
    authorization?
    <AnimatePresenceDiv>
      <HeaderSearch onKeyPress={onKeyPress} />
      <div id="searchRecommend">
        <div className='header d-flex flex-row justify-content-between align-items-center justify-content-center'>
          <p className='huge'>ค้นหาล่าสุด</p>
          <p className='small' onClick={delHistoryAll}>ล้าง</p>
        </div>

        <div className='historyAll'>
          {historySearch?.map((history, index) => (
            <div key={index} className='historyItem d-flex flex-row justify-content-between align-items-center justify-content-center'>
              <NavLink  to={`/feed/search/popular/${history.searchText}`}>
                <p>{history.searchText}</p>
              </NavLink>
              <img
                className='delhistory'
                onClick={(e) => {
                  e.stopPropagation();
                  delHistory(index, history.searchHistoryId)}
                }
                src={process.env.PUBLIC_URL + `/images/icon/delSearchIcon.svg`}
                alt="delSearchIcon"
              />
            </div>
            
          ))}
        </div>
      </div>
    </AnimatePresenceDiv>
    :
    <PinConfirm />
  );
};

export default SearchRecommend;
