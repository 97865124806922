import axios from "axios";
// const REST_API_URL = "http://127.0.0.1:8080";
const REST_API_URL = "https://social-app-backend-3r67f.ondigitalocean.app";
class PostList {
    async getPost(findCategory, index, userId) {   
        const url = `${REST_API_URL}/postList/${userId}/${findCategory}/?page=${index}`;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async getPostOfUser(userId, index) {
        
        const url = `${REST_API_URL}/profilelist/${userId}/?page=${index}`;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async createPost(data) {
        const url = `${REST_API_URL}/postList/`;
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async updatePost(data) {
        const url = `${REST_API_URL}/postList/`;
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async deletePost(data) {
        const url = `${REST_API_URL}/postList/`;
        return axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            },
            data: data
        });
    }
    async getOldPost(postId, userId) {
        const url = `${REST_API_URL}/commentlist/${postId}/${userId}/`;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async uploadImage(file, imageType) {
        const formData = new FormData();
        formData.append('file', file);
        const url = `${REST_API_URL}/uploadImage/${imageType}/`
        return axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async deleteImage(data, type) {
        const url = `${REST_API_URL}/deleteImage/${type}/${data}/`;
          return axios.delete(url,{
           headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem("authToken")}`
           },
       });
   }
}
class CommentList {
    async getPostAndWithComment(postId, userId) {
        const url = `${REST_API_URL}/commentlist/${postId}/${userId}/`;
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async createComment(data) {
        const url = `${REST_API_URL}/commentlist/`;
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async editComment(data) {

        const url = `${REST_API_URL}/commentlist/`;
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
        });
    }
    async deleteComment(data) {

        const url = `${REST_API_URL}/commentlist/`;
        return axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("authToken")}`
            },
            data: data
        });
    }
}

class User {
    // ****
    async getUserLogin(userNameLogin) {        
        const url = REST_API_URL+`/userlogin/${userNameLogin}/`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
    }
    async getUserData(){

        const url = REST_API_URL+`/userlist/${localStorage.getItem("userId")}/`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
    }
    async createUserData(data){

        const url = REST_API_URL+`/userlist/`
        return axios.post(url, data,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
    }

    async getSearchUserData(userId){

        const url = REST_API_URL+`/userlist/${userId}/`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
    }
    async editUserData(data){

        const url = REST_API_URL+`/userlist/`
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
    }
}
class BookmarkList {
    async getBookmarkList(userId){

        const url = REST_API_URL+`/userlist/${userId}/`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })

    }
    async getBookmarkPost(userId, index){

        const url = REST_API_URL+`/bookmarklist/${userId}/?page=${index}`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })

    }
    async addBookmarkList(data){

        const url = REST_API_URL+`/bookmarklist/`
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })

    }
}
class Like {
    async getlike(data){

        const url = REST_API_URL+`/like/`
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
    }
}

class Search {
    async getSearchPost(searchText, categoriesSelected, index, userId){
        const url = REST_API_URL+`/searchPost/${userId}/${searchText}/${categoriesSelected}/?page=${index}`     
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })        
    }
    async getSearchUser(searchText, userId){

        const url = REST_API_URL+`/searchUser/${userId}/${searchText}/`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })    
    }
    async getSearchHashtag(searchText, userId){

        const url = REST_API_URL+`/searchHashtag/${userId}/${searchText}/`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })    
    }
    async getSearchHistory(userId){
       const url = REST_API_URL + `/searchHistory/${userId}/`
       return axios.get(url, {
           headers: {
            //    'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem("authToken")}`
           }
       })    
    }
    async addSearchHistory(data){
        const url = REST_API_URL+`/searchHistory/`    
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
    }
    async delSearchHistory(data){
        const url = REST_API_URL+`/searchHistory/`    
        return axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            },
            data: data
        })
    }
}

class Follow {
    async followUser(data){

        const url = REST_API_URL+`/follow/`
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })        
    }
}


const socialApi = {
    PostList, CommentList, BookmarkList, Like, User, Search, Follow
};

export default socialApi;