import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PostList from '../postList/postList';
import {Col } from 'react-bootstrap';
import SearchEmpty from './searchPageEmpty';
import Loading from '../helper/loading';
import socialApi from '../../../service/services';
import InfiniteScroll from "react-infinite-scroll-component";
import { useUserName } from '../userNameContext';

const Popular = (props) => {
    const navigate = useNavigate()
    const {userId } = useUserName()
    const userService = new socialApi.Search();
    const search = useParams().search || "";
    const categoriesSelected = props.categoriesSelected || "nofilter"
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState("")
    const [hasMore, setHasMore] = useState(true);
    const [index, setIndex] = useState(2);
    let newSearch = search
    let itemPerFetch = 10;

    if(search.startsWith("#")){
      newSearch = search.slice(1)
    }
      useEffect(() => {
        fetchData()
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [newSearch, categoriesSelected]);
    

    const fetchData = async () => {   
        setHasMore(true)             
        try {
            const res = await  userService.getSearchPost(newSearch, categoriesSelected, 1, userId)
            setData(res.data.posts);
            res.data.posts?.length < itemPerFetch ? setHasMore(false) : setHasMore(true)
            setLoading(false)
        } catch (error) {
            if(error.response.status === 401){
                navigate("/feed/search")
            } else {
                console.log(error)
            }   
        } finally {
            setIndex((prevIndex) => prevIndex + 1);
        }
    };

    const fetchMoreData = async () => {                
            try {
                const res = await userService.getSearchPost(newSearch, categoriesSelected, index, userId)
                if (res.data.posts && res.data.posts.length > 0) {
                    setData((prevItems) => [...prevItems, ...res.data.posts]);  
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
            } catch (error) {
                if(error.response.status === 401){
                    navigate("/feed/search")
                } else {
                    console.log(error)
                }   
            } finally {
                setIndex((prevIndex) => prevIndex + 1);
            }
    };
    
    
    return loading ?
            <Loading />
            :
            <Col className='postContainer container'>
                    {
                    data?
                    <InfiniteScroll
                        dataLength={data?.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<Loading />}
                    >
                      <PostList feedPost={data} />
                    </InfiniteScroll>
                    :
                    <SearchEmpty />
                    }
            </Col>    
}

export default Popular