import React, { useState }  from 'react';
import './modalSelectCategories.scss';
import { Offcanvas } from 'react-bootstrap'
import allCategories from './allCategoriesList';

const ModalSelectCategories = (props) => {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const itemAmount = selectedCategories.length;
    const headerTextCate = props.headerTextCate;
    const btnName = props.btnName;
    const numberOfFiler = props.numberOfFiler;
    const countNumberSelected = numberOfFiler === 3 ? `${itemAmount}/3` : "";

    const offcanvasStyles = {
            fontFamily: 'kanit',
            minWidth: '100%',
            maxWidth: '200px',
            height: '85%',        
            padding: '20px', 
            borderRadius: '20px 20px 0 0',                     
            bottom: 0, 
            top: 'auto', 
            inset: 'auto auto 0px auto',
    };
    
    const toggleCategory = (category) => {
        const isSelected = selectedCategories.includes(category);

        if (isSelected) {
            setSelectedCategories((prev) => prev.filter((item) => item !== category));            
        } else {
        if (selectedCategories.length < numberOfFiler) {
            setSelectedCategories((prev) => [...prev, category]);           
        }
        }
    };


    const onColoseModal = () => {
        props.addCategories(false)
    }

    const onSaveCategories = () => {
        props.selectCategories(selectedCategories);
        onColoseModal()
    };
     
    return (
            <Offcanvas style={offcanvasStyles} show={props.showModal} onHide={onColoseModal}  placement='bottom'>
                        <div id='allCategories'>
                            <div className='d-flex flex-row justify-content-between align-items-center mb-4'>
                                <h2 className='headerText'>{headerTextCate}</h2>
                                
                                <p onClick={onColoseModal} className='closeModal'>
                                    <img src={process.env.PUBLIC_URL +  `/images/icon/closeBtnGrey.svg`} alt="closeBtnGrey"/>
                                </p>
                            </div>
                            <div className='d-flex flex-column justify-content-between boxContainer'>
                                <div className='d-flex flex-row flex-wrap gap-3'>
                                    {
                                        allCategories.map((cate, index)=>{
                                        if(index === 0) {
                                            return null
                                        }else
                                        return (<p onClick={() => toggleCategory(cate.thName)} key={index} 
                                            className={`mb-2 d-flex align-items-center justify-content-center categoriesItem ${selectedCategories.includes(cate.thName) ? 'categoriesItemSelected' : 'categoriesItemNotSelected'}`}>
                                                {cate.thName}
                                            </p>
                                        )})
                                    }
                                </div>
                                <div className='btnContainer'>
                                    <button className={`${itemAmount > 0 ? 'enable':'disable' }`} onClick={onSaveCategories}>
                                        {btnName} {countNumberSelected}
                                    </button>
                                </div>
                            </div>
                        </div>
            </Offcanvas>
    )
}

export default ModalSelectCategories