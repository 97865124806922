import React, { useState } from 'react';
import { Container } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import HeaderOther from '../../helper/HeaderStyle/headerOther';
import socialApi from '../../../../service/services';
import '../../index.scss'
import './chat.scss'

const Chat = () => {
  let headerText = "แชท";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: 'user' };
      setMessages([...messages, newMessage]);
      setInput('');
      // Simulate a response
      setTimeout(() => {
        const botMessage = { text: 'This is a simulated response.', sender: 'bot' };
        setMessages([...messages, newMessage, botMessage]);
      }, 1000);
    }
  };

  return (
    <div id='Chat'>
      <HeaderOther
        headerText={headerText}
        prevLink="/"
      />
      <div id='chat' style={{margin: '2vw'}}>
        <div className="chat-container">
          <div className="messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
          </div>
          <div className="input-container">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            />
            <button onClick={handleSend} className='mr12'>Send</button> 
            <button onClick={()=> setMessages([])} className='clearChat'>Clear Chat</button> 
          </div>
        </div>

      </div>
    </div>


  )
}

export default Chat