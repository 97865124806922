import React from 'react';
import { NavLink } from "react-router-dom";
import './botBar.scss'

const BotBar = ()=> {

const botItemList = [
    {botIcon:"Home",botName:"หน้าหลัก", link: null},
    {botIcon:"People",botName:"พูดคุย", link: "/feed/all"},
    {botIcon:"Jigsaw",botName:"บริการ", link: "/service"},
    {botIcon:"Condo",botName:"ซื้อ-ขาย", link: null},
    {botIcon:"Ham",botName:"อื่นๆ", link: null},
]

return (
    <div id="bottomBar" className='d-flex flex-row justify-content-around justify-content-center align-items-center'>
        {
            botItemList.map((botItem, index)=>(
                <NavLink key={index} to={botItem.link} className="d-flex flex-column align-items-center">
                    <img
                        className='mb-1'
                        width="20px"
                        height="20px"
                        src={process.env.PUBLIC_URL + `/images/icon/boticon${botItem.botIcon}.svg`}
                        alt="botIcon"
                    />
                    <p className={`${botItem.botName === "พูดคุย" ? "textActive":"textNotActive"}`}>{botItem.botName}</p>
                </NavLink>
            ))
        }
    </div>
    )
}
export default BotBar