import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import {Container} from 'react-bootstrap';
import { useUserName } from '../userNameContext'
import './createPostElement.scss';

const CreatePostElement = (props) => {
    const navigate = useNavigate()
    const userNameProfile = props.userProfileName
    const userImage = props.userProfileImage
    const { userId } = useUserName();
  
    const createPost = () => {
        navigate("/createpost")
    }
    return (
        <Container>
            <div id="createPostElement">
                <div className='d-flex flex-row'>                    
                        <NavLink to={`/dashboard/app/profilelist/${userId}`}>
                            {userImage ?
                            userImage?.startsWith("profile") ?                                                      
                            <img className="rounded-circle me-4"  width="50px" height="50px"  src={process.env.PUBLIC_URL + `/images/profile/${userImage}.png`} alt="userImage"/>
                            :
                            <img className="rounded-circle me-4"  width="50px" height="50px"  src={userImage} alt="userImage"/>                          
                            : null}
                        </NavLink>                                   
                    <button className='text-left' onClick={createPost}>คุณกำลังคิดอะไรอยู่ {userNameProfile}</button>
                </div>
            </div>
        </Container>
    )
}

export default CreatePostElement