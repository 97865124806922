// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#headerFeed .headerName {
  cursor: pointer;
}
#headerFeed .showHeaderDescription {
  display: block;
}
#headerFeed p {
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 900px) {
  #headerFeed .showHeaderDescription {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  #headerFeed p {
    font-weight: bold;
    font-size: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/helper/HeaderStyle/headerFeed.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AAEI;EACI,cAAA;AAAR;AAEI;EACI,iBAAA;EACA,eAAA;AAAR;;AAIA;EAEQ;IACI,aAAA;EAFV;AACF;AAMA;EAEQ;IACI,iBAAA;IACA,eAAA;EALV;AACF","sourcesContent":["#headerFeed{\n    .headerName {\n        cursor: pointer;\n    }\n    .showHeaderDescription {\n        display: block;\n    }\n    p {\n        font-weight: bold;\n        font-size: 20px;        \n    }\n}\n\n@media screen and (max-width: 900px) {\n    #headerFeed{\n        .showHeaderDescription {\n            display: none;\n        }\n    }\n}\n\n@media screen and (max-width: 350px) {\n    #headerFeed{\n        p {\n            font-weight: bold;\n            font-size: 15px;        \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
