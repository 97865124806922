import React, { useState } from 'react';
import { Container } from 'react-bootstrap'
import { NavLink, useNavigate } from "react-router-dom";
import HeaderOther from '../helper/HeaderStyle/headerOther';
import socialApi from '../../../service/services';
import '../index.scss'
import './chat.scss'
import BotBar from '../helper/botBar';

const ServicePage = () => {
  let headerText = "บริการ";
  const [serviceLust] = useState([
    { text: "Chat BOT", link: "/chat", img: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABAdJREFUaEPtmlusjUcUx39oSGjdiXpzeyEklMSTkrR9aInEPREhLk1feJCKoHW/NBQpkRDiFkEO4hoRNOrVNRVCUH3TJhpKtXVn/qyROcfevu/b3+xjS856OWfvb2bN/z+z1sz817fr8co+AKYCI4DuQEP7vtx/HgEXgF3ACuBJ1gHrGfhjQP+snSO3Pwl8lpWECEwHfogMplR3wrI0S2cROAN8Yp1mAT8CWtraMIXqNBcFC20wYemTZWAReBjEfKNaBO9xaswH9kETp8+pTQSeB631+V1YyRjqCERarroV8BMZ5sBi2yF0yMU0HVbL3GYxM3BalhW47wZqEhN54Otft11/WG4C7/0KlGnyC7otSwjVEcgwA2VZgfc+B+p2oSIhlOYc6AQcAnQuTQGOFgvHSr0LfWuHnXArP9YA+k4352pWqQS6AFJoHwdojzj5OcQJsP9DBpVKQBhbOwLrDLTHfBz4KtQslUzAg5ZK9IpN3620AsTL55WgyCQrfWwXU2QznGrUtu5zYiBw2BMINfF3ljyVqIl3AiONxHWgK/A4bVXid+ALQB1lvYDdQIcMp22apm+rSrQArlpuyNckYIOvCyk5Pk0YQbGoZRR41ZFapkGUoc0vwOcJdSFVMHzZ5bJWwQsYX5kbDvQoUpmb58AfiAzeV+aqLDmTKnONgT+Bj2xiuiVVIea6g2SONd4H9Atm/rbN2LkMsxyj6Q5glDmalYVAOPjflhN3gMHA/iA/YoB8m48xwFZrsKcUAh78aeA356wjcAPQ/aU2TDl41ga6mJVAzbAp+R6fg2kzQJMou5dEQJWDRda4UMwr6RrYc20ET3MAS9tVxYB/rPHdJAKdg6vsUBfv52uMcjO4cLUH/kiLIke75oByT3Y7iUDSOIpFxaSsdxCbSf3yPO8J+J3vSl4COhcGGZpx7mTekgdZyr5jgc3WtiovAb2WWm7O9gDDUoLI02wbMNocJJ4DSQNJeOh+IlNitQP+S+qU47kSWCexrxh2z7sCwnLJbob6X7vWkhwAk7qGK64NpVcMAor9TTay9mcdaNpyY1srFzrXAN1KZd9IscUgoHPgV/eqtJs53mu58CwiA+HUq1ht5TLdRHXpfBKDgBxKIR0MAC8AZkck8D0wP/AnXfxakcUaR69qJUhkumL0BU5FcK5rfDgZ2vVUYnlpsVagPrAeGF9olkok0RZYbb8e8C4kpL4MRU8MAoXAbwQm1ngDmpZHU2CC07tSgEpcb6rUSRNX26bzEigGXno1TRKrfxtAs62ryADTFyLhTeG41kqMbyi2vARWAZPTTm0J7aQzvgZ+LtY3L4FyVbD1C5afgO3BW/yCHPISyPsOQWH2F3ALUOnmhPvVjKoTqXX2C23K5UiuAkelAAAAAElFTkSuQmCC` }
  ])

  return (
    <div id='Chat'>
      <HeaderOther
        headerText={headerText}
        prevLink="/"
      />

      <Container>
        <div id='servicePage' style={{ margin: '2vw' }}>
          <div className='row Ci_UseAll'>
            {
              serviceLust.map((ex) => {
                return (
                  <NavLink to={ex.link}>
                    <div className='col col-6'>
                      <div className='card'>
                        <div className='card-body' style={{ padding: 24 }}>
                          <p className='text-black' style={{ marginBottom: 0 }}>
                            <img src={ex.img} className='w36' />
                            <label className='ml-12'>{ex.text}</label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                )
              })
            }

          </div>
        </div>
      </Container>
      <BotBar />

    </div>


  )
}

export default ServicePage