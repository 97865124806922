import React from "react";
import './loginHeader.scss';

const LoginHeader = (props) => {
  const headerText = props.headerText;
  const backToPreviousStep = () => {
    props.handleStateLoginChange()
  }
  return (
      <div id="loginHeader" className="d-flex flex-row p-4">
            <img
                onClick={backToPreviousStep}
                src={process.env.PUBLIC_URL + `/images/icon/leftArrow.svg`}
                className="leftArrow"
                alt="user"
                loading="lazy"
            />                       
            <h2 className="text-white ms-3">{headerText}</h2>
      </div>
  );
};

export default LoginHeader;
