import React from 'react'
import PostList from '../postList/postList';
import FeedBroadEmpty from './feedBroadEmpty';
import {Container} from 'react-bootstrap'
import CreatePostElement from '../createPost/createPostElement';

const FeedBroadPost = (props) => {
    return (
        <>                       
            {
                (props.feedPost?.length > 0 ?
                    <Container>
                        <CreatePostElement 
                            userProfileName={props.userProfileName}
                            userProfileImage={props.userProfileImage}
                        />
                        <PostList 
                            feedPost={props.feedPost} 
                        />  
                    </Container>    
                        :
                    <FeedBroadEmpty />
                )
            }                 
        </>
    )
}

export default FeedBroadPost