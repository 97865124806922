// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#createPostElement {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
#createPostElement img {
  cursor: pointer;
  border-radius: 50%;
  margin-right: 20px;
}
#createPostElement button {
  cursor: pointer;
  padding-left: 15px;
  color: #65676b;
  text-align: left;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #F8F9FA;
  border: 1px solid transparent;
}
#createPostElement button:hover {
  transition: ease-in-out 0.3s;
  background-color: #ebeef3;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/createPost/createPostElement.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAAI;EACI,eAAA;EACA,kBAAA;EACA,kBAAA;AAER;AAAI;EACI,eAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,WAAA;EACA,4BAAA;EACA,6BAAA;EACA,+BAAA;EACA,gCAAA;EACA,yBAAA;EACA,6BAAA;AAER;AAAI;EACI,4BAAA;EACA,yBAAA;AAER","sourcesContent":["#createPostElement {\n    background-color: rgb(255, 255, 255);\n    padding: 20px;\n    margin-bottom: 20px;\n    border-radius: 5px;\n    img {\n        cursor: pointer;\n        border-radius: 50%;\n        margin-right: 20px;\n    }\n    button {\n        cursor: pointer;\n        padding-left: 15px;\n        color: rgb(101, 103, 107);\n        text-align: left;\n        width: 100%;\n        border-top-left-radius: 20px;\n        border-top-right-radius: 20px;\n        border-bottom-left-radius: 20px;\n        border-bottom-right-radius: 20px;\n        background-color: #F8F9FA;\n        border: 1px solid transparent;\n    }\n    button:hover  {\n        transition: ease-in-out 0.3s;\n        background-color: #ebeef3;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
