// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#broadEmpty {
  height: 100vh;
  display: flex;
  justify-content: center;
}
#broadEmpty p {
  font-size: 14px;
  color: #808080;
  line-height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/feed/feedBroadEmpty.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAAI;EACI,eAAA;EACA,cAAA;EACA,iBAAA;AAER","sourcesContent":["#broadEmpty {\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    p {\n        font-size: 14px;\n        color: #808080;\n        line-height: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
