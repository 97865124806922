// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#termOfServicePage {
  height: 100vh;
}
#termOfServicePage .termHeader {
  background-color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/login/termOfService/termOfService.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,yBAAA;AAER","sourcesContent":["#termOfServicePage {\n    height: 100vh;\n    .termHeader {\n        background-color: #000000;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
