import React , {useState} from 'react';
import { Container} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import HeaderOther from '../helper/HeaderStyle/headerOther';
import './createProfile.scss';
import './profileImgSelected.scss'
import ProfileImgSelected from './profileImgSelected';
import socialApi from '../../../service/services';
import { useUserName } from '../userNameContext';
import Resizer from "react-image-file-resizer";

const CreaetProfile = () => {
    const userNameLogin = localStorage.getItem('userName')
    const { setUserName, setUserId } = useUserName();
    const [userImage, setUserImage] = useState("")
    const [userImageAvatar, setUserImageAvatar] = useState("")

    const userService = new socialApi.User()
    const userServicePost = new socialApi.PostList()

    const [inputValue, setInputValue] = useState();
    const navigate = useNavigate()
    let headerText = "โปรไฟล์บอร์ดพูดคุย";
    
    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleClearClick = () => {
      setInputValue('');
    };

    const userAvatar = [
      {name:"custom", path:""},
      {name:"profile01", path:"profile01"},
      {name:"profile02", path:"profile02"},
      {name:"profile03", path:"profile03"},
      {name:"profile04", path:"profile04"},
      {name:"profile05", path:"profile05"},
      {name:"profile06", path:"profile06"},
      {name:"profile07", path:"profile07"},
      {name:"profile08", path:"profile08"},
      {name:"profile09", path:"profile09"},
      // {name:"profile10", path:"profile10"},
    ]

    const onLogin = async () => {  
      let uploadedImages = ""

      if(userImageAvatar !== 0){
        uploadedImages =`profile0${userImageAvatar}`
      } else if(userImage){ 
        uploadedImages = await uploadFile(userImage);
      } else {
        uploadedImages  =`profile01`
      }

      const data = {
        userNameLogin: userNameLogin,
        userName: inputValue,
        userImage: uploadedImages,
      };

      try {
        const res = await userService.createUserData(data)
        if (res.status === 200) {          
            const login = await userService.getUserLogin(userNameLogin)
            await localStorage.setItem("userId", login.data.user._id);            
            setUserName(userNameLogin); 
            setUserId(login.data.user._id); 
            
            navigate(`/feed/all`);         
        }
      } catch (error) {
        if(error.response.status === 401){
            navigate("/")
        } else {
            console.log(error)
        }   
      }
    };
    
        // IMAGE
    const resizeImg = (file) => {
          return new Promise((resolve, reject) => {
              Resizer.imageFileResizer(
                  file,
                  450,
                  450,
                  'JPEG',
                  100,
                  0,
                  (uri) => {
                      resolve(uri);
                  },
                  'file',
                  450, 
                  450,
                  false,
                  () => {
                      reject(new Error('Image resizing failed.'));
                  }
              );
          });
    };
  
    const uploadFile = async (files) => {
      try {
          const resize = await resizeImg(files)
          const response = await userServicePost.uploadImage(resize, "userImage");
          let res = response.data.imageUpload;
          return res;
      } catch (error) {
          console.error('Error uploading file:', error);
          throw error; 
      }
  };
  

    const handleSetImage = (file) => {
      setUserImage(file)
    }
    const handleDefaultImage = (indexDefault) => {    
      setUserImageAvatar(indexDefault)
    }

    return (
      <div id='createProfile'>
        <HeaderOther 
          headerText={headerText}
          prevLink="/"
        />
        <Container className='d-flex flex-column align-items-center pt-5'>
                <div id="carousel">
                  <ProfileImgSelected 
                    userAvatar={userAvatar}
                    handleSetImage={handleSetImage}
                    handleDefaultImage={handleDefaultImage}
                  />
                </div>
                <div className='creatUserName d-flex flex-row justify-content-center'>
                  <input 
                      className='mt-3'
                      placeholder='ตั้งชื่อเล่น'
                      type="text"
                      onChange={handleInputChange}
                      value={inputValue || ""}
                      maxLength="20"
                  />                 
                  <img 
                    className="clearInput" 
                    src={process.env.PUBLIC_URL + `/images/icon/clearInput.svg`}
                    onClick={handleClearClick}      
                    alt="clearInput"            
                  />
                  
                </div>
                <div className='d-flex flex-column align-items-center mb-5 mt-2'>
                  <p className='ruleOfCreateProfile'>กำหนดชื่อเล่น และรูปโปรไฟล์เพื่อเข้าร่วมบอร์ดพูดคุย</p>
                  <p className='ruleOfCreateProfile'>ตั้งชื่อตัวอักษรไม่เกิน 20 ตัว</p>
                </div>
                <button 
                  onClick={onLogin}
                  disabled={!inputValue}
                  className={`createProfile createProfileBtn${inputValue?.length > 0 ? "Enable" : "Disable"}`}>
                    สร้างโปรไฟล์
                </button>
                
        </Container>
       
      </div>

    )
}

export default CreaetProfile