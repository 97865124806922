import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import loginApi from '../../../service/serviceLogin';
import './resetPin.scss';
import OtpPage from '../login/otpPage';
import PinCreatePage from '../login/pinCreatePage';
import PinConfirmPage from '../login/pinConfirmPage';
import ResetPinOtp from '../login/resetPinOtp';
// import Swal from 'sweetalert2';

const ResetPinProcess = (props) => {
    const userNameLogin = localStorage.getItem("userName")   
    // const [otpReceiveStatus, setOtpReceiveStatus] = useState(true) 
    const loginOTP = new loginApi.OTP()
    const [stateResetPin, setStateResetPin] = useState("otp")
    const [loginRef, setLoginRef] = useState("")
    const [loading, setLoading] = useState(false)
    const location = useLocation();

    useEffect(()=> {
        if(location?.state?.haveOtp) {
            handleStateLoginChange('pinCreate')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const enterForGetOTP = () => {
        setLoading(true)
        let data = {
            "msisdn": userNameLogin,
        }        
        try {
            loginOTP.getOTP(data).then((res)=>{ 
                if(res.data.reference) {
                    setStateResetPin('otpReq')
                    setLoginRef(res.data.reference)   
                    setLoading(false)
                }
            })
        } catch (error) {
            console.log(error)
        }   finally {
        }
    }

    const handleStateLoginChange = (loginState) => {
        setStateResetPin(loginState)
    }

    

    return (
      <div id='resetPin'>
                {
                    stateResetPin === 'otp' &&
                    <ResetPinOtp 
                        enterForGetOTP={enterForGetOTP}
                        loading={loading}
                    />
                }
                {
                    stateResetPin === 'otpReq' &&
                    <OtpPage 
                        loginRef={loginRef}
                        handleStateLoginChange={handleStateLoginChange}
                        resetPinProcess={true}
                    />                    
                }
                {
                    stateResetPin === 'pinCreate' &&
                    <PinCreatePage 
                        handleStateLoginChange={handleStateLoginChange}
                        resetPinProcess={true}
                    />
                }
                {   stateResetPin === "pinConfirm" &&
                    <PinConfirmPage
                        handleStateLoginChange={handleStateLoginChange}
                        resetPinProcess={true}
                    />
                }
        </div>
    )
}

export default ResetPinProcess