// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#navigation-tab {
  background-color: #FFFFFF;
  position: sticky;
}
#navigation-tab ul {
  padding: 0;
  margin: 0;
}
#navigation-tab ul li {
  display: inline-block;
}
#navigation-tab ul li a {
  display: block;
  padding: 14px 10px;
  text-decoration: none;
  font-family: "medium";
  color: #808080;
  border-bottom: 5px solid transparent;
}
#navigation-tab ul li a.active {
  font-family: large;
  color: #1567B4;
  border-bottom: 5px solid #1567B4;
  transition: ease-in-out 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/app/userProfile/user-profile-navigation.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,gBAAA;AACJ;AAAI;EACI,UAAA;EACA,SAAA;AAER;AADQ;EACI,qBAAA;AAGZ;AAFY;EACI,cAAA;EACA,kBAAA;EACA,qBAAA;EACA,qBAAA;EACA,cAAA;EACA,oCAAA;AAIhB;AADY;EACI,kBAAA;EACA,cAAA;EACA,gCAAA;EACA,4BAAA;AAGhB","sourcesContent":["#navigation-tab {\n    background-color: #FFFFFF;\n    position: sticky;\n    ul{\n        padding: 0;    \n        margin: 0;\n        li {            \n            display: inline-block;\n            a {\n                display: block;\n                padding: 14px 10px;\n                text-decoration: none;\n                font-family: 'medium';\n                color: #808080;\n                border-bottom: 5px solid transparent;\n            }\n\n            a.active {\n                font-family: large;\n                color: #1567B4;\n                border-bottom: 5px solid #1567B4;\n                transition: ease-in-out 0.3s;\n            }\n        }\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
