import React , {useState} from 'react';
import { Container} from 'react-bootstrap'
import './pinPage.scss'
import LoginHeader from './loginHeader';
import OtpInput from "react-otp-input";
import Swal from 'sweetalert2';
import loginApi from '../../../service/serviceLogin';

const PinCreatePage = (props) => {
    const loginPIN = new loginApi.PIN()
    const [pin, setPin] = useState('');
    
    const handlePinChange = (inputPin) => {
        setPin(inputPin);
    };

    const createPin = async () => {
        const data = {
            "password": pin
        }
        try {
            await loginPIN.createPIN(data)
        } catch (error) {
            console.log(error)
        } finally {
            props.handleStateLoginChange("pinConfirm")
        }
    }

    const resetInput = async () => {
        setPin('')
    }



    const nextStateLogin = async () => {            
        try {
            const result = await Swal.fire({
              icon: 'warning',
              iconColor: '#1567B4',
              imageWidth: 100,
              title: "ยืนยันรหัส PIN ",
              html: `
                <label className='swal2-title-custom text-center'>
                รหัส PIN ของคุณจะถูกใช้
                เพื่อปลดล็อกการเข้าใช้ระบบนี้
                และป้องกันการเข้าถึงข้อมูลของคุณ</label>
              `,
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'ยืนยันที่จะใช้',
              cancelButtonText: 'แก้ไข',
              reverseButtons: true,
              confirmButtonColor: '#1567B4',
            });
        
            if (result.isConfirmed) {
                createPin()
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                resetInput()
            }
          } catch (error) {
            console.error(error);
          }
    }

    return (
        <div id="pinPage">
            <LoginHeader 
                handleStateLoginChange={()=>props.handleStateLoginChange("otp")}
                headerText="ตั้งรหัส PIN"
            />
            <Container className='d-flex flex-column align-items-center pt-5'>
                    <div className='pinTextDescription d-flex flex-column align-items-center'>
                        {
                            <div className='mb-5 d-flex flex-column align-items-center'>
                                <p>กำหนดรหัส PIN  6 หลัก</p>
                                <p>Enter a 6-digit number pin</p>
                            </div>
                        }
                        <OtpInput
                            value={pin}
                            onChange={handlePinChange}
                            numInputs={6}
                            renderSeparator={<span style={{ width: "8px" }}></span>}
                            inputType="number"
                            renderInput={(props, index) => {
                                return (
                                <input
                                    {...props}
                                    value=""
                                    style={{
                                        border: `2px solid 
                                        ${pin?.length === index ? "white" : "transparent"}`,
                                        borderRadius: "50%",
                                        width: "30px",
                                        height: "30px",
                                        fontSize: "12px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "transparent",
                                        backgroundColor: props.value ? "#ffffff" : "#4D4D4D",
                                    }}
                                />
                            )}}
                                caretRender={() => null}
                                isInputNum={true}
                                shouldAutoFocus={true}
                        />
                        <button 
                            onClick={nextStateLogin}
                            className={`mt-5 pinBtn pinBtn${pin?.length === 6 ? "Enable" : "Disable"}`}>
                            ถัดไป
                        </button>           
                    </div>
            </Container>
        </div>
    )
}

export default PinCreatePage