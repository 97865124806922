import React from 'react';
import './feedBroadEmpty.scss';

const FeedBroadEmpty = () => {

    return (
        <>
            <div id="broadEmpty" className='d-flex flex-column align-items-center mt-4'>    
                <img 
                    className='mb-5'
                    src={process.env.PUBLIC_URL + `/images/icon/haveNoPost.svg`}
                    width="77px"
                    height="77px"
                    alt="emptyFeed"
                />
                <div className='d-flex flex-column align-items-center'>
                    {/* <p>เหงาไหม ? ลองสร้างโพสต์ของคุณ</p>
                    <p>เพื่อพูดคุยปรึกษากับเพื่อนๆในโครงการ</p> */}
                    <p>** บ้านนี้ไม่เหงาแน่นอน!** </p>
                    <p>มาร่วมกันสร้างพื้นที่ออนไลน์แสนอบอุ่น</p> 
                    <p>พูดคุย แชร์ไอเดีย และปรึกษาปัญหา</p> 
                    <p>กับเพื่อนบ้านของเรากันเถอะ</p>

                </div>                                                                        
            </div>
        </>
    )
}

export default FeedBroadEmpty