import React, { useState, useEffect, useRef }  from 'react'
import {useParams, NavLink} from 'react-router-dom'
import {Col, Container} from 'react-bootstrap'
import Card from '../helper/Card'
import HeaderOther from '../helper/HeaderStyle/headerOther'
import Loading from '../helper/loading'
import './postCommnet.scss'
import HashtagText from './postListTextHelper'
import ReactModal from 'react-modal';
import Swal from 'sweetalert2';
import EditModal from '../helper/editModal'
import socialApi from '../../../service/services';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { useUserName } from '../userNameContext'
import allCategories from '../helper/allCategoriesList'
import PinConfirm from '../login/pinConfirm'

const PostComment = ()=> {
    const { userId } = useUserName();
    const myUserID = userId;
    const [authorization, setAuthorization] = useState(true)
    const userServiceComment = new socialApi.CommentList();
    const userServiceUser = new socialApi.User();
    const [onProcess, setOnProcess] = useState(false)

    const paramPostId = useParams().postId;
    
    let headerText = "ความคิดเห็น";

    const findCategory = (cate) =>{
      let cateEng = allCategories.find(category => category.thName === cate)?.engName;
      return cateEng
    }

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);  
    const [commentText, setCommentText] = useState("")
    const [commentId, setCommentId] = useState("")    

    const imageNumberThatNotBackDrop = 2
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);


    const [showModal, setShowModal] = useState(false);
    const [showModalEDIT, setShowModalEDIT] = useState(false);
    const [userImage, setUserImage] = useState();
    
    const [activeButton, setActiveButton] = useState("post");
    const [inputValue, setInputValue] = useState('');
    const [inputEditValue, setInputEditValue] = useState('');

    // onPress to Edit
    const [action, setAction] = useState();
    const timerRef = useRef();
    const isLongPress = useRef();
    const [isFocused, setIsFocused] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    useEffect(() => {       
      ReactModal.setAppElement('#postCommnet'); 
      fetchData();
      fetchDataUser();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    
    useEffect(() => {
      const handleGlobalKeyPress = (e) => {
        if(!onProcess) {
          handleKeyPress(e);
        }
      };
  
      document.addEventListener('keydown', handleGlobalKeyPress);
  
      return () => {
          document.removeEventListener('keydown', handleGlobalKeyPress);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onProcess, activeButton, inputValue, inputEditValue]);

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
          e.preventDefault();
          if (activeButton === 'post' && inputValue) {
              postThisCommnet();
          } else if (activeButton === 'edit' && inputEditValue) {
            editThisCommnet();

          }
      }
    };
  
  
    const fetchData = async () => {
      setLoading(true);
      setAuthorization(true)
      try {
        const res = await userServiceComment.getPostAndWithComment(paramPostId, myUserID);
        setData(res.data.post);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setAuthorization(false)
        } else {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    };

    const fetchDataUser = async () => {
        setAuthorization(true)
        try {
          const res = await userServiceUser.getSearchUserData(myUserID);
          setUserImage(res.data.user.userImage)
        } catch (error) {
          if(error.response.status === 401){
            setAuthorization(false)
          } else {
              console.log(error)
          }   
        } 
    };

    const postThisCommnet = async () => {
      setAuthorization(true)
      setOnProcess(true)
      try {
        const data = {
          "postId":paramPostId,
          "userId":myUserID,
          "commentText":inputValue,    
        }              
        const res = await userServiceComment.createComment(data);
        if(res.status === 200){
          window.location.reload();
        }            
      } catch (error) {
        if(error.response.status === 401){
          setAuthorization(false)
        } else {
            console.log(error)
        }  
      } finally {
        setOnProcess(false)
      }
    };

    const editThisCommnet = async () => {
      const data = {
          "postId": paramPostId,
          "userId": myUserID,
          "commentId" : commentId,
          "commentText" : inputEditValue,
      }

      try {
          userServiceComment.editComment(data).then((response)=>{
            if(response.status === 200){
              window.location.reload()
            }
          })
        } catch (error) {
          if (error.response && error.response.status === 401) {
            setAuthorization(false)
          } else {
            console.log(error);
          }
        }
        setActiveButton('post')        
    }
    const onDeleteComment = async () => {
      onColoseModal()
      try {
          const result = await Swal.fire({
            icon: 'question',
            iconColor: '#1567B4',
            imageWidth: 100,
            html: `
              <label className='swal2-title-custom text-center'>คุณแน่ใจใช่ไหมที่จะลบ</label>
            `,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'ลบ',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true,
            confirmButtonColor: '#1567B4',
          });
      
          if (result.isConfirmed) {
            await deleteComment();
            Swal.fire({
              icon: 'success',
              title: 'ลบสำเร็จ',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.error(error);
        }
    }

    const deleteComment = async () => {
          const data = {
            commentId: commentId,
            postId: paramPostId,
            userId: myUserID,
          }
          try {
            userServiceComment.deleteComment(data).then((response)=>{
              if (response.status === 200) {
                window.location.reload();
              }
            })
          } catch (error) {
            if (error.response && error.response.status === 401) {
              setAuthorization(false)
            } else {
              console.log(error);
            }
          }
    };

    const onEditComment = () => {
      onColoseModal()        
      handleFocus()
      setShowModalEDIT(true)
      setInputEditValue(commentText)
      setActiveButton('edit')
    }

    ////////// helper function //////////////
    const handleFocus = () => {
        setIsFocused(true);        
        setReadOnly(false)
    };
    
    const handleBlur = () => {
        setReadOnly(true)
    };
    

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleInputEditChange = (event) => {
        setInputEditValue(event.target.value);
    };

    const onColoseModal = () => {
        setShowModal(false)
    }

    const onColoseModalEDIT = () => {
        setShowModalEDIT(false)
    }

    const startPressTimer = (commentId, commentText, userId) => {
        isLongPress.current = false;
        if(userId === myUserID) {
            timerRef.current = setTimeout(() => {
                isLongPress.current = true;
                setAction('longpress');
                setShowModal(true);
                setCommentText(commentText);
                setCommentId(commentId);
              }, 1000);
        }
    }
    
      // Function to handle click event
    const handleOnClick = (e) => {
        if (isLongPress.current) {
          return;
        }
        setAction('click');
    }
    
    // Function to handle touch start event
    const handleOnTouchStart = (commentId, commentText, userId) => {
        startPressTimer(commentId, commentText, userId);
    }
    
    const handleOnTouchEnd = () => {
        if (action === 'longpress') return;
        clearTimeout(timerRef.current);
    }

    const openLightbox = (image) => {
      setSelectedImage(image);
      setLightboxOpen(true);
    };

    const handleOnContextMenu = (event) => {
      event.preventDefault(); // Prevent the default context menu behavior
    };

    const editModalStyle = {
      content: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '300px',
        maxWidth: '30%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '0px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',            
      },
      overlay: {
        zIndex: 2000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    }

    const commentModalStyle = {
        content: {
            position: "fixed",
            bottom: "0",
            left: "0",
            right: "0",
            height: "50px", // Adjust this value as needed
            backgroundColor: "transparent",
            border: "transparent",
            boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.1)",  
        },
        overlay: {
          zIndex: 2000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
    }
    
    const commentStyle = {
        WebkitTouchCallout: isLongPress.current ? 'none' : 'auto',
        WebkitUserSelect: isLongPress.current ? 'none' : 'auto',
        KhtmlUserSelect: isLongPress.current ? 'none' : 'auto',
        MozUserSelect: isLongPress.current ? 'none' : 'auto',
        msUserSelect: isLongPress.current ? 'none' : 'auto',
        userSelect: isLongPress.current ? 'none' : 'auto',
    };

    return (    
        <div id="postCommnet">    
          {
            authorization ?                           
            <>
              <HeaderOther 
                  headerText={headerText}       
              />    
              { 
              loading?
                <Loading />
                      :
                  data &&                
                  <>
                      <Container>                
                              <Col sm={12} className="row m-0 p-0">                    
                                  <Card className="card-block card-stretch card-height p-0">
                                      <Card.Body>
                                      <div className="user-post-data d-flex flex-row align-items-center">
                                                  <NavLink to={`/dashboard/app/profilelist/${data.userId}/`}>
                                                    <div className="me-3">
                                                      {                                                                                                                                                                     
                                                        data.userImage.startsWith("profile") ?                                                      
                                                        <img className="rounded-circle" width="50px" height="50px" src={process.env.PUBLIC_URL + `/images/profile/${data.userImage}.png`} alt="posterImage"/>
                                                        :
                                                        <img className="rounded-circle" width="50px" height="50px" src={data.userImage} alt="posterImage"/>
                                                      }
                                                      </div>
                                                  </NavLink>
                                                  <div className="d-flex flex-column w-100">
                                                      <div className="d-flex flex-row">
                                                          <NavLink to={`/dashboard/app/profilelist/${data.userId}/`}>
                                                              <h5 className="mb-0 d-inline-block mb-2 me-2">{data.userName}</h5>
                                                          </NavLink>
                                                          <div className="card-post-toolbar">
                                                              <p className="mb-0 text-secondary">{data.timestamp}</p>
                                                          </div>
                                                      </div>
                                                      <div>
                                                        {
                                                          data.categories?.map((cate,index)=>(
                                                            <NavLink key={index} to={`/feed/${findCategory(cate)}`}>  
                                                              <span className='feedCategory me-2 p-2'>{cate}</span>
                                                            </NavLink>
                                                          ))
                                                        }
                                                      </div>
                                                  </div>                               
                                      </div>
                                          <div className="mt-3">
                                            <HashtagText 
                                              text={data.postText}
                                              hashtags={data.hashTags}
                                            />
                                          </div>
                                        <div className="user-post">
                                          <div className="container">
                                            <div className="row">
                                              { data.postImage &&
                                                data.postImage.map((img, index) => {
                                                return (
                                                  index < 3 &&
                                                  <div
                                                  className={`col postImageContainer ${
                                                    data.postImage?.length > 2 && index === 2 ? 'moreThanThree' : ''
                                                  }`}
                                                  key={index}
                                                  >
                                                    {index <= 1 && (
                                                      <img
                                                        src={img}
                                                        alt={`post${index}`}
                                                        className="rounded"
                                                        height="100%"
                                                        width="100%"
                                                        onClick={() => openLightbox(img)}
                                                      />
                                                    )} 
                                                    {index === 2 && (
                                                      <>
                                                        <img
                                                          src={img}
                                                          alt={`post${index}`}
                                                          className="rounded"
                                                          height="100%"
                                                          width="100%"
                                                          onClick={() => openLightbox(img)}
                                                        />
                                                        {data.postImage?.length > 3 && <div className="postImageContainer blackdrop" onClick={() => openLightbox(data.postImage)}>
                                                          +{data.postImage.length - imageNumberThatNotBackDrop}
                                                        </div>}
                                                      </>
                                                    )}
                                                  {lightboxOpen && (
                                                      Array.isArray(selectedImage) ?
                                                      <Lightbox onClose={() => setLightboxOpen(false)} startIndex={2} images={selectedImage} />
                                                      :
                                                      <Lightbox onClose={() => setLightboxOpen(false)} image={selectedImage} />
                                                    )}
                                                  </div>
                                                )})
                                                }
                                            </div>
                                          </div>
                                        </div>

                                      </Card.Body>
                                  </Card>
                              </Col>                                                
                              
                              {data.comments?.length > 0 && 
                              <Col sm={12} className="row m-0 p-0 mb-5">                    
                                  <Card className="p-0 card-block card-stretch card-height">
                                      <Card.Body className="p-0 mb-1">       
                                      {data.comments?.map((comment, index)=>(                                    
                                              <div className="commentOnPost d-flex flex-row mb-2" 
                                                  onClick={handleOnClick}
                                                  onMouseDown={()=>handleOnTouchStart(comment.commentId, comment.commentText, comment.userId)}
                                                  onMouseUp={handleOnTouchEnd}
                                                  onTouchStart={()=>handleOnTouchStart(comment.commentId, comment.commentText, comment.userId)}
                                                  onTouchEnd={handleOnTouchEnd}
                                                  onContextMenu={handleOnContextMenu}
                                                  style={commentStyle}
                                                  key={index}
                                              >
                                                  <div className="me-3">          
                                                    {                                                                                                                                                                     
                                                        comment.userImage.startsWith("profile") ?                                                      
                                                        <img className="rounded-circle" width="35px" height="35px"  src={process.env.PUBLIC_URL + `/images/profile/${comment.userImage}.png`} alt="posterImage"/>
                                                        :
                                                        <img className="rounded-circle" width="35px" height="35px"  src={comment.userImage} alt="posterImage"/>
                                                    }                                                                                                                 
                                                  </div>
                                                  <div className="d-flex flex-column commentBox">
                                                      <div className="card-post-toolbar d-flex flex-row">
                                                          <h5 className="mb-0 d-inli ne-block me-2">{comment.userName}</h5>
                                                          <p className="mb-0 text-secondary">{comment.timestamp}</p>
                                                      </div>   
                                                      <div className='mb-0 mt-2'>
                                                        <HashtagText 
                                                          text={comment.commentText}
                                                        />
                                                        
                                                      </div>
                                                  </div> 
                                              </div>
                                      ))}
                                      </Card.Body>
                                  </Card>
                              </Col>}                    
                      </Container>
                      <div className='d-flex flex-row postedBox justify-content-center'>   
                              {!isFocused &&                                                                                                                                                                                                        
                                    (userImage?.startsWith("profile") ?                                                      
                                    <img className="rounded-circle me-3 userImageComment" width="35px" height="35px"  src={process.env.PUBLIC_URL + `/images/profile/${userImage}.png`} alt="userImage"/>
                                    :
                                    <img className="rounded-circle me-3 userImageComment" width="35px" height="35px"  src={userImage} alt="userImage"/>)                                                                
                              }
                              <input
                                  placeholder='แสดงความคิดเห็นของคุณ'
                                  onChange={handleInputChange}
                                  value={inputValue}
                                  className="commentInput me-2"
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  readOnly={readOnly}
                              />
                              {isFocused &&
                              <button 
                                onClick={postThisCommnet} 
                                className={`postBtn ${inputValue ? 'focus' : 'blur'}`}
                                disabled={onProcess && !inputValue}
                                >โพสท์</button>
                              }
                      </div>
                      <EditModal 
                          showModal={showModal}
                          customModalStyles={editModalStyle}
                          onColoseModal={onColoseModal}
                          onEditComment={onEditComment}
                          onDelete={onDeleteComment}
                      />
                      <ReactModal 
                          isOpen={showModalEDIT}
                          onRequestClose={onColoseModalEDIT}
                          style={commentModalStyle}            
                      >
                          <div className='d-flex flex-row postedBox justify-content-center'>   
                                  <input
                                      placeholder='แสดงความคิดเห็นของคุณ'
                                      onChange={handleInputEditChange}
                                      value={inputEditValue}
                                      className="commentInput me-2"
                                      onFocus={handleFocus}
                                      onBlur={handleBlur}
                                      readOnly={readOnly}
                                  />
                                  <button 
                                  onClick={editThisCommnet} 
                                  className={`postBtn ${inputEditValue ? 'focus' : 'blur'}`}>
                                    โพสท์
                                  </button>
                          </div>
                      </ReactModal>
                  </>                                 
              }
            </>
              :
            <PinConfirm />
          }
        </div>        
    )
}

export default PostComment