
import React from 'react';
import { NavLink } from "react-router-dom";
import allCategories from './allCategoriesList';
import './navigation.scss';

const Navigation = () => {
        return (
            <div id="navigation-tab">
                    <ul className='scrollable-list'>
                        {
                            allCategories.map((category, index)=> (
                                <li key={index}>
                                    <NavLink to={`/feed/${category.engName}`} >
                                        {category.thName}
                                    </NavLink>
                                </li>
                            ))
                        }
                    </ul>
            </div>
        )
}


export default Navigation;