// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalEditComment {
  padding: 0;
}
.modalEditComment p {
  width: 100%;
  margin: 0;
  padding: 20px 64px;
  font-weight: 600;
  color: #4D4D4D;
  font-size: 16px;
}
.modalEditComment p:first-child {
  border-bottom: 1px solid #F5F5F5;
}
.modalEditComment a {
  color: #4D4D4D;
}
.modalEditComment .btnAction {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/helper/editModal.scss"],"names":[],"mappings":"AACA;EACI,UAAA;AAAJ;AACI;EACI,WAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AACR;AACI;EACI,gCAAA;AACR;AACI;EACI,cAAA;AACR;AACI;EACI,eAAA;AACR","sourcesContent":["\n.modalEditComment {\n    padding: 0;\n    p {\n        width: 100%;\n        margin: 0;\n        padding: 20px 64px;    \n        font-weight: 600;\n        color: #4D4D4D;\n        font-size: 16px;    \n    }\n    p:first-child {\n        border-bottom: 1px solid #F5F5F5;\n    }\n    a {\n        color: #4D4D4D;\n    }\n    .btnAction {\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
