import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./searchPageHashTag.scss";
import SearchEmpty from "./searchPageEmpty";
import Loading from "../helper/loading";
import socialApi from "../../../service/services";
import { useUserName } from "../userNameContext";

const Hashtag = () => {
    const {userId} = useUserName()
    const userService = new socialApi.Search();
    const search = useParams().search || "";
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState("")

    let newSearch = search
    if(search.includes("#")){
      newSearch = newSearch.slice(1)
    }
    const navigate = useNavigate()
    useEffect(() => {
      fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newSearch]);

    const fetchData = async () => {
        setLoading(true)
        try {
          const res = await userService.getSearchHashtag(newSearch, userId)
          setData(res.data.hashtags);
        } catch (error) {
          if(error.response.status === 401){
            navigate("/feed/search")
          } else {
              console.log(error)
          }   
        } finally {
          setLoading(false);
        }
    };   

    const toSearch = (hashtag)=> {
        navigate(`/feed/search/popular/%23${hashtag}`);  
    }
  return loading ? (
          <Loading />
        ) : data?.length > 0 ? (
            <div id="searchHashTag">
            {data?.map((hashtag, index) => {
                return (
                <div key={index} className="hashtag d-flex justify-content-between m-4" onClick={()=>toSearch(hashtag.hashtagName)}>
                    <div className="nameHashtag d-flex flex-row align-items-center">
                    <img
                        className="me-2"
                        width="45px"
                        height="45px"
                        src={process.env.PUBLIC_URL + `/images/icon/hashtagImage.svg`}
                        alt="userIamge"
                    />
                    <div>
                        <h3>{hashtag.hashtagName}</h3>
                        <p className="m-0">โพสต์ที่เกี่ยวข้อง {hashtag.postId.length} โพสต์</p>
                    </div>
                    </div>
                </div>
                );
            })}
            </div>
        ) : (
            <SearchEmpty />
        );
};

export default Hashtag;
