// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#userEmpty {
  height: 40vh;
  display: flex;
  justify-content: center;
}
#userEmpty p {
  font-size: 14px;
  color: #808080;
  line-height: 5px;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/app/userProfile/user-profile-empty.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAAI;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AAER","sourcesContent":["#userEmpty {\n    height: 40vh;\n    display: flex;\n    justify-content: center;\n    p {\n        font-size: 14px;\n        color: #808080;\n        line-height: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
