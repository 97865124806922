// AnimatePresenceDiv.js
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const AnimatePresenceDiv = ({ children }) => {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <AnimatePresence>
        <motion.div
                initial={{ 
                    x: "110vw",
                    opacity: 0.5,               
                }}
                animate={{ 
                    x: 0,
                    opacity: 1,
                    transition: {
                    duration: 0.3,
                    ease: "easeOut",
                    },                          
                }}
                exit={{ 
                    x: "-110vw",
                    opacity: 0.5,
                    transition: {
                        duration: 0.3,
                        ease: "easeIn",
                    },
                }}
                >
                  {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default AnimatePresenceDiv;
