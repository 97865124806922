import React, {useState,} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import './searchRecom.scss';
import HeaderSearch from '../helper/HeaderStyle/headerSearch';
import Navigation from './searchPageNavigation';
import Popular from './searchPagePopular';
import User from './searchPageUser';
import Hashtag from './searchPageHashTag';
import AnimatePresenceDiv from '../helper/animatedContainer';
import { useUserName } from '../userNameContext';

const SearchPage = () => {
    const { userId } = useUserName();
    const myUserID = userId;
    const searchType = useParams().searchType || "";
    const [searchText, setSearchText] = useState(useParams().search);
    const navigate = useNavigate();
    const [categoriesSelected, setCategoriesSelected] = useState("nofilter");

    const handleInput = (event)=>{
      setSearchText(event.target.value)
    }
    const onKeyPress = (event) => {
      let newSearch = searchText
      if (event.key === 'Enter' && searchText !== "") {   
        if(searchText.startsWith("#")) {
          newSearch = searchText.replace("#", "%23")          
        }        
        navigate(`/feed/search/${searchType}/${newSearch}`);                
      }
    };

    const handleSelectedCategory = (category) => {
      setCategoriesSelected(category)
    }

    return (
        <AnimatePresenceDiv>
            <HeaderSearch 
                searchType={searchType}
                prevUrl="/feed/all"
                handleInput={handleInput}
                searchText={searchText}
                onKeyPress={onKeyPress}
                handleSelectedCategory={handleSelectedCategory}
            />        
            <Navigation />
            {
                searchType === 'popular' && 
                <Popular 
                  searchType={searchType}
                  search={searchText}
                  myUserID={myUserID}     
                  categoriesSelected={categoriesSelected}             
                />
            }
            {
                searchType === 'user' && 
                <User 
                  searchType={searchType}
                  search={searchText}
                  myUserID={myUserID}      
                />
            }
            {
                searchType === 'hashtag' && 
                <Hashtag 
                  searchType={searchType}
                  search={searchText} 
                  myUserID={myUserID}      
                />
            }
        </AnimatePresenceDiv>
    )
}

export default SearchPage