import React from 'react'
import { Modal } from 'react-bootstrap';
import './userModalStyle.scss'

const UserDate = (props) => {
    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal} centered className="customModalNoInput">
             <img className="close" onClick={props.handleCloseModal} src={process.env.PUBLIC_URL +  `/images/icon/closeBtn.svg`}/>
            <Modal.Header className="custom-modal-header">                
                <Modal.Title>เลือกวันเกิด</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                <div className='d-flex flex-row justify-content-between'>
                    <span>FEB</span>
                    <span>28</span>
                    <span>1996</span>
                </div>
            </Modal.Body>
            <Modal.Footer className="custom-modal-footer">
                <span className="me-5" onClick={props.handleCloseModal}>ตกลง</span>
                <span className="" onClick={props.handleCloseModal}>ยกเลิก</span>
            </Modal.Footer>
        </Modal>
    )
}

export default UserDate