import React, { useState, useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import "./searchPageUser.scss";
import SearchEmpty from "./searchPageEmpty";
import Loading from "../helper/loading";
import socialApi from "../../../service/services";
import { useUserName } from "../userNameContext";

const User = (props) => {
  const {userId} = useUserName()
  const userServiceUser = new socialApi.Search();
  const userServiceFollow = new socialApi.Follow();
  const myUserID = props.myUserID
  const navigate = useNavigate()
  const search = useParams().search || "";
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState("")
  let newSearch = search
  if(search.includes("#")){
    newSearch = encodeURIComponent(search)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const followPeople = async (follow) => {  
    setData((prevUsers) =>
        prevUsers.map((prev) =>
        prev.userId === follow
            ? { ...prev, alreadyFollow: !prev.alreadyFollow }
            : prev
        )
    );
    try {
      const data = {
        "follower": myUserID,
        "following": follow,
      };
      await userServiceFollow.followUser(data)    
    } catch (error) {
      if(error.response.status === 401){
          navigate("/feed/search")
      } else {
          console.log(error)
      }   
    }
  };


  const fetchData = async () => {
      setLoading(true)
      try {
        const res = await userServiceUser.getSearchUser(newSearch, userId)
        setData(res.data.users);

      } catch (error) {
        if(error.response.status === 401){
            navigate("/feed/search")
        } else {
            console.log(error)
        }   
      } finally {
        setLoading(false);
      }
  };

  return loading ? (
        <Loading />
        ) : data?.length > 0 ? (
            <div id="searchUser">
            {data?.map((user, index) => {
                return (
                <div key={index} className="d-flex justify-content-between m-4">
                    <NavLink to={`/dashboard/app/profilelist/${user.userId}/`}>
                      <div className="userRecom d-flex flex-row align-items-center">
                      {user.userImage.startsWith("profile") ?                                                      
                      <img className="me-2" width="45px" height="45px"  src={process.env.PUBLIC_URL + `/images/profile/${user.userImage}.png`} alt="userImage"/>
                        :
                      <img className="me-2" width="45px" height="45px"  src={user.userImage} alt="userImage"/>
                      } 
                      <div>
                          <h3>{user.userName}</h3>
                          <p className="m-0">{user.description}</p>
                      </div>
                      </div>                
                    </NavLink>
                    {
                        (myUserID !== user.userId) &&
                        <button
                            className={`followBtn ${user.alreadyFollow ? "unfollow" : "follow"}`}
                            onClick={() => followPeople(user.userId)}
                        >
                            {user.alreadyFollow ? "เลิกติดตาม" : "ติดตาม"}
                        </button>   
                    }
             
                </div>
                );
            })}
            </div>
        ) : (
            <SearchEmpty />
        );
};

export default User;
