import React, { useState } from "react";
import './profileImgSelected.scss'
import { TiChevronLeftOutline, TiChevronRightOutline } from 'react-icons/ti';

const MAX_VISIBILITY = 2;

const Card = ({ name, image, index, onImageChange }) => {
  const [userImage, setUserImage] = useState(null);

  const upLoadUserImage = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      setUserImage(files[0]);
      onImageChange(files[0]);
    }
  };

  return (
    <div className='card'>
      <div className='userProfileImg'>
        {userImage ? (
          <img
            style={{ borderRadius: "50%" }}
            src={URL.createObjectURL(userImage)} alt="uploadedUserImage" width="150px" height="150px" />
        ) : (
          <img className="profileImg" src={process.env.PUBLIC_URL + `/images/profile/profile0${index}.png`} alt="profile"
            width="150px" height="150px"
          />
        )}

        {index === 0 &&
          <>
            <label htmlFor="fileInput" className='uploadIcon'>
              <img src={process.env.PUBLIC_URL + `/images/icon/uploadImageProfile.svg`} alt="uploadIcon" />
              <input
                type="file"
                accept="image/*"
                id="fileInput"
                onChange={upLoadUserImage}
                style={{ display: 'none' }}
              />
            </label>
          </>
        }
      </div>
    </div>
  )
}

const Carousel = ({ children, onActiveChange }) => {
  const [active, setActive] = useState(0);
  const count = React.Children.count(children);
  const handleSelect = (index) => {
    setActive(index);
    onActiveChange(index);
  };

  return (
    <div className='carousel'>
      {active > 0 && <button className='nav left' onClick={() => handleSelect(i => i - 1)}>
        <TiChevronLeftOutline />
      </button>}
      {React.Children.map(children, (child, i) => {
        return (
          <div className='card-container' style={{
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 3,
            '--direction': Math.sign(active - i),
            '--abs-offset': Math.abs(active - i) / 3,
            'pointerEvents': active === i ? 'auto' : 'none',
            'opacity': Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
            'display': Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
          }}>
            {React.cloneElement(child, { onImageChange: (image) => child.props.onImageChange(image) })}
          </div>
        )
      })}
      {active < count - 1 && <button className='nav right' onClick={() => handleSelect(i => i + 1)}>
        <TiChevronRightOutline />
      </button>}
    </div>
  );
};

const ProfileImgSelected = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleActiveChange = (index) => {
    setActiveIndex(index)    
  };

  const handleSetImage = (image) => {    
    // setUserImage(image);
    props.handleSetImage(image);
  };

  props.handleDefaultImage(activeIndex);

  return (
    <Carousel onActiveChange={handleActiveChange}>      
      {props.userAvatar.map((avatar, i) => (
        <Card
          key={i}
          title={'Card ' + (i + 1)}
          name={avatar.name}
          image={avatar.path}
          index={i}
          onImageChange={handleSetImage}
        />
      ))}
    </Carousel>
  );
};

export default ProfileImgSelected;
