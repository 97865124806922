import React from 'react';
import './searchPageEmpty.scss';

const SearchEmpty = () => {

    return (
        <>
            <div id="broadEmpty" className='d-flex flex-column align-items-center mt-4'>    
                <img 
                    className='mb-5'
                    src={process.env.PUBLIC_URL + `/images/icon/searchNotFound.svg`}
                    width="77px"
                    height="77px"
                    alt="emptySearch"
                />
                <div className='d-flex flex-column align-items-center'>
                    <h4>ไม่พบคำที่คุณค้นหา</h4>
                    <p>ลองใช้คำอื่นค้นหาอีกครั้ง</p>
                </div>                                                                        
            </div>
        </>
    )
}

export default SearchEmpty