import React , {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './termOfService.scss'
import TermPolicy from './termPolicy';
import TermOfUse from './termOfUse';
import TermMarketingPurpose from './termMarketingPurpose';
import TermMarketingReveal from './termMarketingReveal';
import TermPartner from './termPartner';
import loginApi from '../../../../service/serviceLogin';

const TermOfService = () => {    
    const pdpaAccept = new loginApi.PDPA()
    const navigate = useNavigate()
    const [stepTerm, setStepTerm] = useState(1)
    const [acceptPolicy, setAcceptPolicy] = useState("")
    const [acceptTermOfUse, setAcceptTermOfUse] = useState("")
    const [acceptMarketingPurpose, setAcceptMarketingPurpose] = useState("")
    const [acceptMarketingReveal, setAcceptMarketingReveal] = useState("")
    const [acceptPartner, setAcceptPartner] = useState("")

    const handleStepTerm = (step) => {
        setStepTerm(step)
    }

    const sendPdpaAccept = async () => {
        
        const data = {
            "accept_policy": acceptPolicy,
            "accept_term_of_use": acceptTermOfUse,
            "accept_marketing": acceptMarketingPurpose && acceptMarketingReveal, 
            "accept_partner": acceptPartner
        }   
        try {
            const res = await pdpaAccept.acceptPdpa(data)
            if(res.data.status){
                if (localStorage.getItem("userId")) {                            
                    navigate("/feed/all")                      
                } else if(!localStorage.getItem("userId")){
                    navigate("/selectProfile")
                }   
            }                        
        } catch (error){
            console.log(error)
        }
    }

    useEffect(() => {
        if(acceptPartner !== ""){
            sendPdpaAccept();
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptPartner]);

    const handleAcceptPolicy = () => {
        setAcceptPolicy(true)
    }
    const handleAcceptTermOfUse = () => {
        setAcceptTermOfUse(true)
    }
    const handleAcceptMarketingPurpose = (accept) => {
        setAcceptMarketingPurpose(accept)
    }
    const handleAcceptMarketingReveal = (accept) => {
        setAcceptMarketingReveal(accept)
    }
    const handleAcceptPartner = (accept) => {
        setAcceptPartner(accept)

    }
    
    return (
        <div id="termOfServicePage">
            { stepTerm === 1 && <TermPolicy 
                handleStepTerm={handleStepTerm}
                handleAcceptPolicy={handleAcceptPolicy}    
            />}
            { stepTerm === 2 && <TermOfUse 
                handleStepTerm={handleStepTerm}
                handleAcceptTermOfUse={handleAcceptTermOfUse}
            />}
            { stepTerm === 3 && <TermMarketingPurpose 
                handleStepTerm={handleStepTerm}
                handleAcceptMarketingPurpose={handleAcceptMarketingPurpose}
            />}
            { stepTerm === 4 && <TermMarketingReveal 
                handleStepTerm={handleStepTerm}
                handleAcceptMarketingReveal={handleAcceptMarketingReveal}
            />}
            { stepTerm === 5 && <TermPartner
                handleStepTerm={handleStepTerm}
                handleAcceptPartner={handleAcceptPartner}
                sendPdpaAccept={sendPdpaAccept}    
            />}
        </div>
    )
}

export default TermOfService