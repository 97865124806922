// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pinPage {
  height: 100vh;
  background-color: #000000;
}
#pinPage .pinTextDescription {
  color: #ffffff;
}
#pinPage .requestOtpAgain {
  cursor: pointer;
}
#pinPage .pinBtn {
  color: #FFFFFF;
  min-width: 326px;
  min-height: 48px;
  border-radius: 4px;
  padding: 12px 24px;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}
#pinPage .pinBtnDisable {
  background-color: #BFBFBF;
  border: 1px solid #BFBFBF;
}
#pinPage .pinBtnEnable {
  background-color: #1567B4;
  border: 1px solid #1567B4;
}
#pinPage .textRed {
  color: #ff0000;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/login/pinPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;AACJ;AAAI;EACI,cAAA;AAER;AAAI;EACI,eAAA;AAER;AACI;EACI,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,4BAAA;EACA,eAAA;AACR;AACI;EACI,yBAAA;EACA,yBAAA;AACR;AACI;EACI,yBAAA;EACA,yBAAA;AACR;AAEI;EACI,cAAA;AAAR","sourcesContent":["#pinPage {\n    height: 100vh;\n    background-color: #000000;\n    .pinTextDescription {\n        color: #ffffff;\n    }\n    .requestOtpAgain {\n        cursor: pointer;\n    }\n\n    .pinBtn {\n        color: #FFFFFF;\n        min-width: 326px;\n        min-height: 48px;\n        border-radius: 4px;\n        padding: 12px 24px;\n        transition: ease-in-out 0.2s;\n        cursor: pointer;\n    }\n    .pinBtnDisable {\n        background-color: #BFBFBF;\n        border: 1px solid #BFBFBF;\n    }\n    .pinBtnEnable {\n        background-color: #1567B4;\n        border: 1px solid #1567B4;\n        \n    }\n    .textRed {\n        color: #ff0000;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
