// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#searchHashTag {
  background-color: #fff;
  height: 85vh;
  padding-top: 10px;
}
#searchHashTag .nameHashtag img {
  border-radius: 50%;
}
#searchHashTag .nameHashtag h3 {
  font-family: "kanit";
  font-size: 16px;
  font-weight: 400;
  color: #0F0F0F;
}
#searchHashTag .nameHashtag p {
  font-size: 12px;
  font-weight: 300;
  color: #808080;
}
#searchHashTag .hashtag {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/search/searchPageHashTag.scss"],"names":[],"mappings":"AACA;EACI,sBAAA;EACA,YAAA;EACA,iBAAA;AAAJ;AAGQ;EACI,kBAAA;AADZ;AAGQ;EACI,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADZ;AAGQ;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AADZ;AAII;EACI,eAAA;AAFR","sourcesContent":["\n#searchHashTag {\n    background-color: #fff;\n    height: 85vh;\n    padding-top: 10px;\n\n    .nameHashtag {\n        img {\n            border-radius: 50%;\n        }\n        h3 {\n            font-family: 'kanit';\n            font-size: 16px;\n            font-weight: 400;\n            color: #0F0F0F;\n        }\n        p {\n            font-size: 12px;\n            font-weight: 300;\n            color: #808080;\n        }\n    }\n    .hashtag {\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
